import React from 'react';
import {makeStyles, useTheme,} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavArrowButton from "./UI/buttons/NavArrowButton";
import Lottie from 'react-lottie';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Hidden from "@material-ui/core/Hidden";
import CallToAction from "./UI/CallToAction";

import documentAnimation from '../animations/documentsAnimation/data';
import scaleAnimations from '../animations/scaleAnimation/data.json';
import automationAnimations from '../animations/automationAnimation/data.json';
import uxAutomation from '../animations/uxAnimation/data';

import lightbulbicon from "../assets/bulb.svg";
import cashicon from "../assets/cash.svg";
import stopwatchicon from "../assets/stopwatch.svg";
import root from "../assets/root.svg"

const useStyles = makeStyles(theme => ({
    rowContainer: {
        paddingLeft: "5em",
        paddingRight: "5em",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "1.5em",
            paddingRight: "1.5em",
        }
    },
    arrowContainer: {
        marginTop: "0.5em"
    },
    heading: {
        maxWidth: "40em"
    },
    itemContainer: {
        maxWidth: "40em"

    }

}))

export default function CustomSoftware(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    const documentOptions = {
        loop: true,
        autoplay: true,
        animationData: documentAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const scaleOptions = {
        loop: true,
        autoplay: true,
        animationData: scaleAnimations,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const automationOptions = {
        loop: true,
        autoplay: true,
        animationData: automationAnimations,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const uxOptions = {
        loop: true,
        autoplay: true,
        animationData: uxAutomation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Grid container direction="column">
            {/* ------- Software na zakazku ------- */}
            <Grid
                item
                container
                direction="row"
                className={classes.rowContainer}
                justify={matchesMD ? "center" : undefined}
                style={{marginTop: matchesXS ? "1em" : "2em"}}>
                <Hidden mdDown>
                    <Grid item className={classes.arrowContainer} style={{marginRight: "1em", marginLeft: "-3.5em"}}>
                        <NavArrowButton link="/services" back onclick={() => props.setSelectedIndex(0)}/>
                    </Grid>

                </Hidden>
                <Grid item container direction="column" className={classes.heading}>
                    <Grid item>
                        <Typography variant="h2" align={matchesMD ? "center" : undefined}>
                            Programujeme software na zakázku
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            Ať už vyměňujete starý software nebo vymýšlíte nová řešení, <span>ORKA Solutions</span> je
                            tu proto, aby
                            vám pomohl s technologií vašeho podnikání.
                        </Typography>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            Používání běžného komerčního softwaru vám dává spoustu věcí, které nepotřebujete, bez
                            některých věcí, které potřebujete, a nakonec řídí způsob vaší práce.
                            Bez použití jakéhokoli softwaru riskujete, že se ocitnete za konkurencí a přijdete o
                            obrovské úspory ze zvýšené efektivity.
                        </Typography>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            Naše zákaznická řešení jsou od základu navržena s ohledem na vaše potřeby, přání a cíle.
                            Tento proces spolupráce vytváří dokonale vyladěný software, který je mnohem efektivnější při
                            zlepšování pracovního postupu a snižování nákladů než obecné možnosti
                        </Typography>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            Vytváříme přesně to, co přesně, jak přesně chcete.
                        </Typography>
                    </Grid>

                </Grid>
                <Hidden mdDown>
                    <Grid item className={classes.arrowContainer}>
                        <NavArrowButton link="/mobileapps" onclick={() => props.setSelectedIndex(2)}/>
                    </Grid>
                </Hidden>
            </Grid>
            {/* ------- Software na zakazku Ikonu------- */}
            <Grid
                item
                container
                direction="row"
                className={classes.rowContainer}
                justify="center"
                style={{marginTop: "15em", marginBottom: "20em"}}>
                <Grid item container direction="column" md alignItems="center" style={{maxWidth: "40em"}}>
                    <Grid item>
                        <Typography variant="h4">
                            Šetřete energii
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img src={lightbulbicon} alt="save energy icon"/>
                    </Grid>
                </Grid>
                <Grid item container direction="column" md alignItems="center" style={{
                    maxWidth: "40em",
                    marginTop: matchesSM ? "10em" : 0,
                    marginBottom: matchesSM ? "10em" : 0
                }}>
                    <Grid item>
                        <Typography variant="h4">
                            Šetřete peníze
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img src={cashicon} alt="save energy icon"/>
                    </Grid>
                </Grid>
                <Grid item container direction="column" md alignItems="center" style={{maxWidth: "40em"}}>
                    <Grid item>
                        <Typography variant="h4">
                            Šetřete čas
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img src={stopwatchicon} alt="save energy icon"/>
                    </Grid>
                </Grid>
            </Grid>
            {/* -------- Digitalizace a Scale ------- */}
            <Grid
                item
                container
                justify="space-around">
                {/* -------- Digitalizace ------- */}
                <Grid
                    item
                    container
                    className={classes.rowContainer}
                    style={{marginBottom: matchesMD ? "15em" : 0}}
                    md
                    direction={matchesSM ? "column" : "row"}>
                    <Grid item container direction="column" md>
                        <Grid item>
                            <Typography variant="h4" align={matchesSM ? "center" : undefined}>
                                Digitální dokumenty a data
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" paragraph align={matchesSM ? "center" : undefined}>
                                Snižte chyby. Redukujte odpad. Snížte náklady.
                            </Typography>
                            <Typography variant="body1" paragraph align={matchesSM ? "center" : undefined}>
                                Každý rok se vynakládají obrovské peníze na nákup, tisk a distribuci papíru. Kromě
                                masivního dopadu na životní prostředí to také poškozuje vaši ekonomiku.
                            </Typography>
                            <Typography variant="body1" paragraph align={matchesSM ? "center" : undefined}>
                                Využitím digitálních formulářů a dokumentů můžete tyto zastaralé výdaje odstranit,
                                urychlit komunikaci a pomoci životnímu prostředí.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md>
                        <Lottie options={documentOptions} isStopped={true}
                                style={{maxHeight: 275, maxWidth: 275, minHeight: 250}}/>
                    </Grid>
                </Grid>
                {/* -------- Scale ------- */}
                <Grid item container className={classes.rowContainer} md direction={matchesSM ? "column" : "row"}>
                    <Grid item md>
                        <Lottie options={scaleOptions} isStopped={true}
                                style={{maxHeight: 260, maxWidth: 280}}/>
                    </Grid>

                    <Grid item container direction="column" md>
                        <Grid item>
                            <Typography variant="h4" align={matchesSM ? "center" : "right"}>
                                Škálovatelnost
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" paragraph align={matchesSM ? "center" : "right"}>
                                Ať už jste velká značka, právě začínáte nebo právě odstartujete, naše aplikační
                                architektura zajišťuje bezbolestný růst a spolehlivost.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* ------- Root section ------- */}
            <Grid
                item
                container
                direction="row"
                className={classes.rowContainer}
                style={{marginTop: "20em", marginBottom: "20em"}}>
                <Grid item container direction="column" alignItems="center">
                    <Grid item>
                        <img src={root} alt="rootimage" height={matchesSM ? "300em" : "450em"}
                             width={matchesSM ? "300em" : "450em"}/>
                    </Grid>
                    <Grid item className={classes.itemContainer}>
                        <Typography variant="h4" gutterBottom align="center">
                            Analýza kořenových příčin
                        </Typography>
                        <Typography variant="body1" align="center">
                            Mnoho aktuálních problémů je pouze důsledkem větších základních problémů.
                        </Typography>
                        <Typography variant="body1" align="center">
                            Můžeme vám pomoci důkladně prozkoumat všechny oblasti vašeho podnikání a vytvořit komplexní
                            plán pro nejefektivnější implementaci technologie.
                        </Typography>
                    </Grid>
                </Grid>

            </Grid>
            {/* -------- Automatizace a ____ ------- */}
            <Grid
                item
                container
                direction={matchesMD ? "column" : "row"}
                className={classes.rowContainer}
                alignItems={matchesMD ? "center" : undefined}
                justify="space-around" style={{marginBottom: "20em"}}>
                {/* -------- Automatizace ------- */}
                <Grid
                    item
                    container
                    direction={matchesSM ? "column" : "row"}
                    className={classes.itemContainer}
                    style={{marginBottom: matchesMD ? "15em" : 0}} md>
                    <Grid item container direction="column" md>
                        <Grid item>
                            <Typography variant="h4" align={matchesSM ? "center" : undefined}>
                                Automatizace
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" paragraph align={matchesSM ? "center" : undefined}>
                                Proč ztrácet čas, když nemusíte?
                            </Typography>
                            <Typography variant="body1" paragraph align={matchesSM ? "center" : undefined}>
                                Můžeme vám pomoci identifikovat procesy pomocí akcí založených na čase nebo událostech,
                                které lze nyní snadno automatizovat.
                            </Typography>
                            <Typography variant="body1" paragraph align={matchesSM ? "center" : undefined}>
                                Zvyšující se efektivita zvyšuje zisky, takže máte více času soustředit se na své
                                podnikání, ne na práci.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md>
                        <Lottie options={automationOptions} isStopped={true}
                                style={{maxHeight: 290, maxWidth: 280}}/>
                    </Grid>
                </Grid>
                {/* -------- UX Section ------- */}
                <Grid item container className={classes.itemContainer} md direction={matchesSM ? "column" : "row"}>
                    <Grid item md>
                        <Lottie options={uxOptions} isStopped={true}
                                style={{maxHeight: 310, maxWidth: 155}}/>
                    </Grid>

                    <Grid item container direction="column" md>
                        <Grid item>
                            <Typography variant="h4" align={matchesSM ? "center" : "right"}>
                                Uživatelsky přátelský design
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" paragraph align={matchesSM ? "center" : "right"}>
                                Pěkný design, který není použitelný, není dobrý design.
                            </Typography>
                            <Typography variant="body1" paragraph align={matchesSM ? "center" : "right"}>
                                Tak proč je tolik kusů softwaru komplikovaných, matoucí a frustrujících?
                            </Typography>
                            <Typography variant="body1" paragraph align={matchesSM ? "center" : "right"}>
                                Díky upřednostňování uživatelů a skutečných způsobů interakce s technologiemi jsme
                                schopni vyvinout jedinečné, personifikované zkušenosti, které spíše řeší problémy než
                                vytvářejí nové.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* -------- Call to action ------- */}
            <Grid item>
                <CallToAction setValue={props.setValue}/>
            </Grid>

        </Grid>
    )
}
