import React, {useState, useEffect} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import {makeStyles, useTheme} from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import logo from '../../assets/images/logo_orka_krivky.svg';

function ElevationScroll(props) {
    const {children} = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

const useStyles = makeStyles(theme => ({
        toolbarMargin: {
            ...theme.mixins.toolbar,
            marginBottom: "3em",
            [theme.breakpoints.down("md")]:
                {
                    marginBottom: "2em"
                },
            [theme.breakpoints.down("xs")]:
                {
                    marginBottom: "1.25em"
                },

        },
        logo: {
            height: "8em",
            [theme.breakpoints.down("md")]:
                {
                    height: "7em"
                },
            [theme.breakpoints.down("xs")]:
                {
                    height: "5.5em"
                },

        },
        logoContainer: {
            fontFamily: "Roboto",

            padding: 0,
            "&:hover": {
                backgroundColor: "transparent"
            }
        },
        tabContainer: {
            marginLeft: 'auto'
        },
        tab: {
            ...theme.typography.tab,
            minWidth: 10,
            marginLeft: "25px"
        },
        button: {
            ...theme.typography.estimate,
            borderRadius: "50px",
            marginLeft: "50px",
            marginRight: "25px",
            height: "45px",
            "&:hover": {
                backgroundColor: theme.palette.secondary.light,
            }
        },
        menu: {
            backgroundColor: theme.palette.common.orkaBlue,
            color: "white",
            borderRadius: "0px",
        },
        menuItem: {
            ...theme.typography.tab,
            opacity: 0.7,
            color: "white",
            "&:hover": {
                opacity: 1
            }
        },
        drawerIcon: {
            height: "50px",
            width: "50px"
        },
        drawerIconContainer: {
            marginLeft: "auto",
            "&:hover": {
                backgroundColor: "transparent"
            }
        },
        drawer: {
            backgroundColor: theme.palette.common.orkaBlue
        },
        drawerItem: {
            ...theme.typography.tab,
            color: "white",
            opacity: 0.7
        },
        drawerItemEstimate: {
            backgroundColor: theme.palette.common.orkaOrange,
        },
        drawerItemSelected: {
            "& .MuiListItemText-root": {
                opacity: 1
            }
        },
        appbar: {
            zIndex: theme.zIndex.modal + 1
        }
    })
);

export default function Header(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const [anchorEl, setAnchorEl] = useState(0);
    const [openMenu, setOpenMenu] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleChange = (e, value) => {
        props.setValue(value)
    };

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        setOpenMenu(true)
    };

    const handleCLose = (e) => {
        setAnchorEl(null);
        setOpenMenu(false)
    };

    const handleMenuItemClick = (e, i) => {
        setAnchorEl(null);
        setOpenMenu(false);
        props.setSelectedIndex(i);
    };

    const menuOptions = [
        {name: "Služby", link: "/services", activeIndex: 1, selectedIndex: 0},
        {name: "Software na zakázku", link: "/customsoftware", activeIndex: 1, selectedIndex: 1},
        {name: "Mobilní aplikace", link: "/mobileapps", activeIndex: 1, selectedIndex: 2},
        {name: "Web Design", link: "/websites", activeIndex: 1, selectedIndex: 3},
        // {name: "Servis firemní IT", link: "/firmyservis", activeIndex: 1, selectedIndex: 4},
    ];

    const routes = [
        {name: "Domů", link: "/", activeIndex: 0},
        {
            name: "Služby",
            link: "/services",
            activeIndex: 1,
            ariaOwns: anchorEl ? "simple-menu" : undefined,
            ariaPopup: anchorEl ? "true" : undefined,
            mouseOver: event => handleClick(event)
        },
        {name: "Revoluce !!!", link: "/revolution", activeIndex: 2},
        // {name: "A-SPED SQL", link: "/aspedsql", activeIndex: 3},
        {name: "Kdo jsme", link: "/about", activeIndex: 3},
        {name: "Kontakt", link: "/contact", activeIndex: 4}
    ];

    useEffect(() => {
        [...menuOptions, ...routes].forEach(route => {
            switch (window.location.pathname) {
                case `${route.link}`:
                    if (props.value !== route.activeIndex) {
                        props.setValue(route.activeIndex)
                        if (route.selectedIndex && route.selectedIndex !== props.selectedIndex) {
                            props.setSelectedIndex(route.selectedIndex)
                        }
                    }
                    break;
                case '/estimate':
                    props.setValue(5);
                    break;
                default:
                    break;
            }
        });
    }, [props.value, menuOptions, props.selectedIndex, routes, props]);

    const tabs = (
        <React.Fragment>
            <Tabs
                value={props.value}
                onChange={handleChange}
                className={classes.tabContainer}
                indicatorColor="primary"
            >
                {routes.map((route, index) => (
                    <Tab
                        key={`${route}${index}`}
                        className={classes.tab}
                        component={Link} to={route.link}
                        label={route.name}
                        aria-owns={route.ariaOwns}
                        aria-haspopup={route.ariaPopup}
                        onMouseOver={route.mouseOver}
                    />
                ))};
            </Tabs>
            <Button
                variant="contained"
                color="secondary"
                component={Link} to={"/estimate"}
                onClick={() =>props.setValue(6)}
                className={classes.button}
            >Poptávka
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCLose}
                classes={{paper: classes.menu}}
                MenuListProps={{onMouseLeave: handleCLose}}
                elevation={0}
                style={{zIndex: 1302}}
                keepMounted
            >
                {
                    menuOptions.map((option, i) => (
                        <MenuItem
                            key={`${option}${i}`}
                            component={Link} to={option.link}
                            classes={{root: classes.menuItem}}
                            onClick={(event) => {
                                handleMenuItemClick(event, i);
                                props.setValue(1);
                                handleCLose()
                            }}
                            selected={i === props.selectedIndex && props.value === 1}
                        >
                            {option.name}
                        </MenuItem>
                    ))
                }
            </Menu>
        </React.Fragment>
    );

    const drawer = (
        <React.Fragment>
            <SwipeableDrawer
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
                classes={{paper: classes.drawer}}
            >
                <div className={classes.toolbarMargin}/>

                <List disablePadding>
                    {routes.map(route => (
                        <ListItem
                            key={`${route}${route.activeIndex}`}
                            onClick={() => {
                                setOpenDrawer(false);
                                props.setValue(route.activeIndex)
                            }}
                            divider button
                            component={Link} to={route.link}
                            selected={props.value === route.activeIndex}
                            classes={{selected: classes.drawerItemSelected}}
                        >
                            <ListItemText
                                disableTypography
                                className={classes.drawerItem}
                            >
                                {route.name}
                            </ListItemText>
                        </ListItem>
                    ))}

                    <ListItem
                        onClick={() => {
                            setOpenDrawer(false);
                            props.setValue(5)
                        }}
                        divider button
                        component={Link} to={"/estimate"}
                        selected={props.value === 5}
                        classes={{root: classes.drawerItemEstimate, selected: classes.drawerItemSelected}}
                    >
                        <ListItemText
                            className={classes.drawerItem}
                            disableTypography
                        >
                            Poptávka
                        </ListItemText>
                    </ListItem>


                </List>
            </SwipeableDrawer>
            <IconButton
                className={classes.drawerIconContainer}
                onClick={() => setOpenDrawer(!openDrawer)}
                disableRipple

            >
                <MenuIcon className={classes.drawerIcon}/>
            </IconButton>
        </React.Fragment>

    );

    return (
        <React.Fragment>
            <ElevationScroll>
                <AppBar position='fixed' className={classes.appbar}>
                    <Toolbar disableGutters>
                        <Button
                            component={Link} to='/'
                            className={classes.logoContainer}
                            onClick={() => props.setValue(0)}
                            disableRipple
                        >
                            <img src={logo} alt='comapny logo' className={classes.logo}/>
                        </Button>
                        {matches ? drawer : tabs}

                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <div className={classes.toolbarMargin}/>
        </React.Fragment>
    )
};
