import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../animations/landinganimation/data';
import {makeStyles, useTheme} from "@material-ui/styles";
import {Link} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CallToAction from "./UI/CallToAction";

import customSoftware from '../assets/Custom Software Icon.svg';
import mobileAppIcon from '../assets/mobileIcon.svg';
import websideIcon from '../assets/websiteIcon.svg';
import aspedsqlBackgroung from '../assets/repeatingBackground.svg';
import infoBackground from '../assets/infoBackground.svg';
import ReadMoreButton from "./UI/buttons/ReadMoreButton";


const useStyles = makeStyles(theme => ({
    animation: {
        maxWidth: "50em",
        minWidth: "21em",
        marginTop: "2em",
        marginLeft: "10%",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "30em"
        }
    },
    estimateButton: {
        ...theme.typography.estimate,
        backgroundColor: theme.palette.common.orkaOrange,
        borderRadius: 50,
        height: 45,
        width: 145,
        marginRight: 40,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light,
        }
    },
    buttonContainer: {
        marginTop: "1em"
    },
    mainContainer: {
        marginTop: "5em",
        [theme.breakpoints.down("md")]: {
            marginTop: "3em"
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "2em"
        },

    },
    heroTextContainer: {
        minWidth: "21.5em",
        marginLeft: "1em",
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0,
        }
    },
    specialText: {
        fontFamily: "Pacifico",
        color: theme.palette.common.orkaOrange,
    },
    subtitle: {
        marginBottom: "1em"
    },
    icon: {
        marginLeft: "2em",
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0
        },
        height: "150px",
        width: "150px"
    },
    servicesContainer: {
        marginTop: "12em",
        [theme.breakpoints.down("sm")]: {
            padding: 25
        }
    },
    revolutionBackground: {
        backgroundImage: `url(${aspedsqlBackgroung})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100%",
        width: "100%"
    },
    revolutionsCard: {
        position: "absolute",
        boxShadow: theme.shadows[10],
        borderRadius: 15,
        padding: "10em",
        [theme.breakpoints.down("sm")]: {
            paddingTop: "8em",
            paddingBottom: "8em",
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: 0,
            width: "100%"
        }
    },
    infoBackground: {
        backgroundImage: `url(${infoBackground})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100%",
        width: "100%"

    }
}));


export default function LandingPage(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Grid container direction="column" className={classes.mainContainer}>
            {/*----- Hero Block -----*/}
            <Grid item>
                <Grid container justify="flex-end" direction="row" alignItems="center">
                    <Grid item sm className={classes.heroTextContainer}>
                        <Typography variant="h2" align="center">
                            ORKA Solutions<br/>
                            IT prostředí je naší doménou.
                        </Typography>
                        <Grid container justify="center" className={classes.buttonContainer}>
                            <Grid item>
                                <Button
                                    className={classes.estimateButton}
                                    variant="contained"
                                    component={Link} to={'/estimate'}
                                    onClick={() => props.setValue(5)}
                                >
                                    Poptávka
                                </Button>
                            </Grid>
                            <Grid item>
                                <ReadMoreButton hero link="/revolution" onclick={() => props.setValue(3)}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm className={classes.animation}>
                        <Lottie options={defaultOptions} height="100%" width="100%"/>
                    </Grid>
                </Grid>
            </Grid>
            {/*----- Custom Software Block -----*/}
            <Grid item>
                <Grid
                    container direction="row"
                    className={classes.servicesContainer}
                    justify={matchesSM ? "center" : undefined}
                >
                    <Grid item style={{marginLeft: matchesSM ? 0 : "5em", textAlign: matchesSM ? "center" : undefined}}>
                        <Typography variant="h4">
                            Software na zakázku
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            Šetřete energii. Šetřete čas. Šetřete peníze
                        </Typography>
                        <Typography variant="subtitle1">
                            Kompletní digitální řešení od průzkumu po <span
                            className={classes.specialText}>oslavu</span>.
                        </Typography>
                        <ReadMoreButton link="/customsoftware" onclick={() => {
                            props.setValue(2);
                            props.setSelectedIndex(1)
                        }}/>
                    </Grid>
                    <Grid item>
                        <img
                            className={classes.icon}
                            alt="Custom software logo"
                            src={customSoftware}/>
                    </Grid>
                </Grid>
            </Grid>
            {/*----- Mobile Apps Block -----*/}
            <Grid item>
                <Grid
                    container direction="row"
                    className={classes.servicesContainer}
                    justify={matchesSM ? "center" : "flex-end"}
                >
                    <Grid item style={{marginLeft: matchesSM ? 0 : "5em", textAlign: matchesSM ? "center" : undefined}}>
                        <Typography variant="h4">
                            iOS/Android programování aplikací
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            Rozšiřte funkčnost. Rozšířit přístup. Zvýšení zapojení.
                        </Typography>
                        <Typography variant="subtitle1">
                            Integrujte své webové prostředí nebo vytvořte samostatnou aplikaci buď s mobilní platformou.
                        </Typography>
                        <ReadMoreButton link="/mobileapps" onclick={() => {
                            props.setValue(2);
                            props.setSelectedIndex(2)
                        }}/>
                    </Grid>
                    <Grid
                        item
                        style={{marginRight: matchesSM ? 0 : "5em"}}
                    >
                        <img className={classes.icon} alt="mobile app logo" src={mobileAppIcon}/>
                    </Grid>
                </Grid>
            </Grid>
            {/*----- Web Side Block -----*/}
            <Grid item>
                <Grid
                    container direction="row"
                    className={classes.servicesContainer}
                    justify={matchesSM ? "center" : undefined}
                >
                    <Grid item style={{marginLeft: matchesSM ? 0 : "5em", textAlign: matchesSM ? "center" : undefined}}>
                        <Typography variant="h4">
                            Web Design
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            Dosáhnout více. Objevit více. Prodat více
                        </Typography>
                        <Typography variant="subtitle1">
                            Optimalizováno pro vyhledávače. Tvořeno pro rychlost.
                        </Typography>
                        <ReadMoreButton link="/websites" onclick={() => {
                            props.setValue(2);
                            props.setSelectedIndex(3)
                        }}/>
                    </Grid>
                    <Grid item>
                        <img
                            className={classes.icon}
                            alt="web side logo"
                            src={websideIcon}/>
                    </Grid>
                </Grid>
            </Grid>
            {/*----- IT Servis Block -----*/}
            <Grid item>
                <Grid
                    container direction="row"
                    className={classes.servicesContainer}
                    justify={matchesSM ? "center" : "flex-end"}
                >
                    <Grid item style={{marginLeft: matchesSM ? 0 : "5em", textAlign: matchesSM ? "center" : undefined}}>
                        <Typography variant="h4">
                            Servis firemní IT
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            Náklady na mzdy, vybavení a školení správců a administrátorů jsou značné <br/> a také
                            neustále rostou
                        </Typography>
                        <Typography variant="subtitle1">
                            Zbavte se této položky v rozpočtu a využijte naší nabídky externí správy sítě.
                        </Typography>
                        <ReadMoreButton link="/itservis" onclick={() => {
                            props.setValue(2);
                            props.setSelectedIndex(2)
                        }}/>
                    </Grid>
                    <Grid
                        item
                        style={{marginRight: matchesSM ? 0 : "5em"}}
                    >
                        <img className={classes.icon} alt="mobile app logo" src={mobileAppIcon}/>
                    </Grid>
                </Grid>
            </Grid>
            {/*----- Revolution Block -----*/}
            <Grid item>
                <Grid container alignItems="center" justify="center" style={{height: "100em", marginTop: "12em"}}>
                    <Card className={classes.revolutionsCard}>
                        <CardContent>
                            <Grid container direction="column" style={{textAlign: "center"}}>
                                <Grid item>
                                    <Typography variant="h3" gutterBottom>
                                        Revoluce !!!
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        Odvážné vize spolu s nejmodernější technologií je recept na revoluci.
                                    </Typography>

                                </Grid>
                                <Grid item>
                                    <ReadMoreButton link="/revolution" onclick={() => props.setValue(3)}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <div className={classes.revolutionBackground}/>
                </Grid>
            </Grid>
            {/*----- Information Block -----*/}
            <Grid item>
                <Grid
                    container
                    direction="row"
                    style={{height: "80em"}}
                    alignItems="center"
                    className={classes.infoBackground}
                >
                    <Grid
                        item
                        container
                        style={{textAlign: matchesXS ? "center" : "inherit"}}
                        direction={matchesXS ? "column" : "row"}
                    >
                        <Grid item sm style={{marginLeft: matchesXS ? 0 : matchesSM ? "2em" : "5em"}}>
                            <Grid
                                container
                                direction="column"
                                style={{marginBottom: matchesXS ? "10em" : 0}}
                            >
                                <Typography variant="h2" style={{color: "white"}}>O nás</Typography>
                                <Typography variant="subtitle2">Let get personal</Typography>
                                <Grid item>
                                    <ReadMoreButton link="/about" onclick={() => props.setValue(3)}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            sm
                            style={{
                                marginRight: matchesXS ? 0 : matchesSM ? "2em" : "5em",
                                textAlign: matchesXS ? "center" : "right"
                            }}
                        >
                            <Grid
                                container
                                direction="column"
                            >
                                <Typography
                                    variant="h2"
                                    style={{color: "white"}}
                                >
                                    Kontaktujte nás
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                >
                                    Napište Ahoj
                                </Typography>
                                <Grid item>
                                    <ReadMoreButton link="/contact" onclick={() => props.setValue(4)}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/*----- Call To Action Block -----*/}
            <Grid item>
                <CallToAction setValue={props.setValue}/>
            </Grid>
        </Grid>
    )
};

