import React from "react";
import {makeStyles, useTheme} from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import CallToAction from "./UI/CallToAction";

import history from '../assets/history.svg';

const useStyles = makeStyles(theme => ({
    rowContainer: {
        paddingLeft: "5em",
        paddingRight: "5em",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "1.5em",
            paddingRight: "1.5em",
        }
    },
    avatarContainer: {
      padding: 1.25,
        alignItems: "center",
        marginTop: "2em",
        marginBottom: "2em",
    },
    missionStatment: {
        fontStyle: "italic",
        fontWeight: 300,
        fontSize: "1.5rem",
        maxWidth: "50em",
        lineHeight: 1.4,
        marginTop: "3em"
    },
    avatar: {
        height: "25em",
        width: "25em",
        [theme.breakpoints.down("sm")] : {
            height: "20em",
            width: "20em",
            maxHeight: 300,
            maxWidth: 300
        }
    },
        specialText: {
        fontFamily: "Pacifico",
        color: theme.palette.common.orkaOrange,
    },

}))


export default function About(props) {
    const classes = useStyles();
    const theme = useTheme()
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <Grid container direction="column">
            {/* ---- Nadpis ---- */}
            <Grid item className={classes.rowContainer} style={{marginTop: matchesMD ? "1em": "2em"}}>
                <Typography align={matchesMD ? "center" : undefined} variant="h2">
                    O nás
                </Typography>
            </Grid>
            {/* Pokec o nas */}
            <Grid
                item
                container
                justify="center"
                className={classes.rowContainer}>
                <Typography variant="h4" align="center" className={classes.missionStatment}>
                    Je jedno jestli jste řemeslník, malé s.r.o. nebo velká akciová spoleřnost, technologie má být nástrojem,
                    který nám umožní nejlepším možným způsobem to na čem záleží, <span className={classes.specialText}>ůspěch</span>.
                    Společnost ORKA Solutions použije tento princip k poskytování rychlého, moderního, levného a estetického software.
                </Typography>
            </Grid>
            <Grid
                item
                container
                className={classes.rowContainer}
                style={{marginTop: "10em", marginBottom: "10em"}}
                direction={matchesMD ? "column" : "row"}
                alignItems={matchesMD ? "center" : undefined}
                justify="space-around">
                <Grid item>
                    <Grid container direction="column" lg style={{maxWidth: "35em"}}>
                        <Grid item>
                            <Typography variant="h4" gutterBottom align={matchesMD ? "center" : undefined}>
                                Historie
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" paragraph style={{fontWeight: 700, fontStyle: "italic"}} align={matchesMD ? "center" : undefined}>
                                Jsme nová společnost v segmentu IT technologií
                            </Typography>
                            <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                                Společnost byla založena v roce 2012, věnuje se IT podpoře firem po celé ČR. Nyní jsme
                                připraveni se angažovat na poli software.<br/>
                            </Typography>
                            <Typography variant="h4" paragraph align={matchesMD ? "center" : undefined}>
                                Proč?
                            </Typography>
                            <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                                Všechno to začalo jednou otázkou: Proč ne všechny firmy využívají dostupnou technologii?
                                Na tuto otázku existuje mnoho různých odpovědí: hospodářské bariéry, sociální bariéry,
                                vzdělávací bariéry a někdy institucionální bariéry.
                            </Typography>
                            <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                                Naším cílem je být hlavní silou při překonávání těchto překážek. Nedávný vývoj v oblasti
                                softwarového inženýrství a výpočetního výkonu, který se zintenzivnil rozšířením chytrých
                                telefonů,
                                otevřel nekonečné světy možností. Věci, které se vždy prováděly ručně, lze nyní provádět
                                digitálně a automaticky a denně se vytvářejí zcela nové metody interakce.
                            </Typography>
                            <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                                Všechny tyto změny mohou pomoci držet krok s konkurencí, a to je to, o co nám jde.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid item container justify="center" lg>
                        <img src={history} alt="open book icon" style={{maxHeight: matchesMD ? 200 : "22em"}}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="column" className={classes.rowContainer} alignItems="center" style={{marginBottom: "15em"}}>
                <Grid item>
                    <Typography align="center" variant="h4" gutterBottom>
                        Náš Team
                    </Typography>
                </Grid>
                <Grid item container style={{marginBottom: "5em"}}>
                    <Grid item container direction="column" className={classes.avatarContainer}  lg>
                        <Grid item>
                            <Typography variant="body1" paragraph align="center">
                                Jiří Fait, majitel
                            </Typography>
                            <Typography variant="body1" paragraph align="center">
                                Programování se věnuji již 30 let
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Avatar className={classes.avatar}>JF</Avatar>
                        </Grid>
                    </Grid>
                    <Grid item container lg direction="column" className={classes.avatarContainer}>
                        <Grid item>
                            <Typography variant="body1" paragraph align="center">
                                Tomasz Michalski, majitel
                            </Typography>
                            <Typography variant="body1" paragraph align="center">
                                Síťovým protokolům se věnuji již od vysoké školy
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Avatar className={classes.avatar}>TM</Avatar>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item>
                <CallToAction setValue={props.setValue}/>
            </Grid>
        </Grid>
    )
}


