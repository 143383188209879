import React from 'react';
import {Link} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import ButtonArrow from "./ButtonArrow";
import {makeStyles, useTheme} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    learnButton: {
        ...theme.typography.learnButton,
        fontSize: "0.7rem",
        height: 35,
        padding: 5,
        [theme.breakpoints.down("sm")]: {
            marginBottom: "2em"
        }
    },
    learnButtonHero: {
        ...theme.typography.learnButton,
        fontSize: "0.9rem",
        height: 45,
        width: 145,
    },
}))

export default function ReadMoreButton(props) {
    const classes = useStyles()
    const theme = useTheme();

    return (
        <Button
            variant="outlined"
            className={props.hero===true ? classes.learnButtonHero : classes.learnButton}
            component={Link} to={props.link}
            onClick={props.onclick}
        >
            <span style={{marginRight: 10}}>Více info</span>
            <ButtonArrow
                height="10"
                width="10"
                fill={theme.palette.common.orkaBlue}
            />
        </Button>
    )
}