import React from 'react';
import {makeStyles, useTheme} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import NavArrowButton from "./UI/buttons/NavArrowButton";
import Lottie from 'react-lottie';

import integrationAnimation from "../animations/integrationAnimation/data.json";
import swiss from '../assets/swissKnife.svg';
import access from '../assets/extendAccess.svg';
import engagment from '../assets/increaseEngagement.svg';

import CallToAction from "./UI/CallToAction";

const useStyles = makeStyles(theme => ({
    rowContainer: {
        paddingLeft: "5em",
        paddingRight: "5em",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "1.5em",
            paddingRight: "1.5em",
        }
    },
    arrowContainer: {
        marginTop: "0.5em"
    },
    heading: {
        maxWidth: "40em"
    }
}))

export default function MobileApps(props) {
    const classes = useStyles()
    const theme = useTheme()
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: integrationAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Grid container direction="column">
            {/* ------- Mobile Apps ------- */}
            <Grid item container direction="row" className={classes.rowContainer}
                  justify={matchesMD ? "center" : undefined} style={{marginTop: matchesXS ? "1em" : "2em"}}>
                <Hidden mdDown>
                    <Grid item className={classes.arrowContainer} style={{marginRight: "1em", marginLeft: "-3.5em"}}>
                        <NavArrowButton link="/customsoftware" back onclick={() => props.setSelectedIndex(1)}/>
                    </Grid>

                </Hidden>
                <Grid item container direction="column" className={classes.heading}>
                    <Grid item>
                        <Typography variant="h2" align={matchesMD ? "center" : undefined}>
                            iOS/Android software na zakázku
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            Mobilní aplikace vám umožní vzít si nástroje na cesty.
                        </Typography>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            Ať už chcete aplikaci pro své zákazníky, zaměstnance nebo pro sebe, můžeme pro každou část
                            vašeho obchodního procesu vytvořit nativní řešení napříč platformami. To vám otevírá zcela
                            nový svět možností využití výhod funkcí mobilního telefonu, jako je fotoaparát, GPS,
                            oznámení push
                            a další.
                        </Typography>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            Pohodlí. Propojení.
                        </Typography>
                    </Grid>

                </Grid>
                <Hidden mdDown>
                    <Grid item className={classes.arrowContainer}>
                        <NavArrowButton link="/websites" onclick={() => props.setSelectedIndex(3)}/>
                    </Grid>
                </Hidden>
            </Grid>

            <Grid container direction={matchesSM ? "column" : "row"} className={classes.rowContainer} style={{marginTop: "15em", marginBottom: "15em"}}>
                <Grid item container direction="column" md>
                    <Grid item>
                        <Typography align={matchesSM ? "center":undefined} variant="h4" gutterBottom>
                            Integrace
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography align={matchesSM ? "center":undefined} variant="body1" paragraph>
                            Naše technologie umožňuje přirozené propojení mezi webovými a mobilními aplikacemi, takže
                            vše, co potřebujete, najdete pohodlně na jednom místě.
                        </Typography>
                        <Typography align={matchesSM ? "center":undefined} variant="body1" paragraph>
                            Díky tomu můžete rozšířit svůj dosah, znovuobjevovat interakce a rozvíjet silnější vztahy s
                            uživateli než kdykoli předtím.
                        </Typography>
                    </Grid>

                </Grid>
                <Grid item md>
                    <Lottie options={defaultOptions} isStopped={true} style={{maxWidth: "20em"}}/>
                </Grid>
                <Grid item container direction="column" md>
                    <Grid item>
                        <Typography align={matchesSM ? "center":"right"} variant="h4" gutterBottom>
                            Simultánní podpora platforem
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography align={matchesSM ? "center":"right"} variant="body1" paragraph>
                            Náš špičkový vývojový proces nám umožňuje vytvářet aplikace pro iPhone, Android a tablety to vše současně.
                        </Typography>
                        <Typography align={matchesSM ? "center":"right"} variant="body1"  paragraph>
                            Tím se výrazně sníží náklady a vytvoří se sjednocená značka na všech zařízeních.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* ------------ Extend Functionality -------------- */}
            <Grid item container direction={matchesMD ? "column" : "row"} className={classes.rowContainer} style={{marginBottom: "15em"}}>
                <Grid item container direction="column" alignItems="center" md >
                    <Grid item>
                        <Typography align="center" variant="h4" gutterBottom>
                            Rozšíření funkcionality
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img src={swiss} alt="svajcar knife"/>
                    </Grid>
                </Grid>
                {/* --- Extend Access --- */}
                <Grid item container direction="column" alignItems="center" md style={{marginTop: matchesMD ? "10em" : 0, marginBottom: matchesMD ? "10em": 0}}>
                    <Grid item>
                        <Typography  align="center" variant="h4" gutterBottom>
                            Rozšíření dostupnosti
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img src={access} alt="rozsireni dostupnosti ikona" style={{maxWidth: matchesXS ? "20em" : "28em"}}/>
                    </Grid>
                </Grid>
                <Grid item container direction="column" alignItems="center" md>
                    <Grid item>
                        <Typography  align="center" variant="h4" gutterBottom>
                            Zvýšení zapojení
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img src={engagment} alt="zvyseni zapojeni ikona"/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
               <CallToAction setValue={props.setValue} />
            </Grid>
        </Grid>
    )
}