import React, {useState} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from './UI/Theme'
import Header from './UI/Header';
import Footer from "./UI/Footer";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import LandingPage from "./LandingPage";
import Services from "./Services";
import FirmyServis from "./FirmyServis";
import PohodaServis from "./PohodaServis";
import CustomSoftware from "./CustomSoftware";
import MobileApps from "./MobileApps";
import Websites from "./Websites";
import Revolutions from "./Revolution";
import About from "./About";
import Contact from "./Contact";
import Estimate from "./Estimate";

function App() {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [value, setValue] = useState(0);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Header
                    value={value}
                    setValue={setValue}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                />
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={(props) => <LandingPage
                            {...props}
                            setValue={setValue}
                            setSelectedIndex={setSelectedIndex}/>}/>
                    <Route
                        exact
                        path="/about"
                        render={(props) => <About
                            {...props}
                            setValue={setValue}
                            setSelectedIndex={setSelectedIndex}/>}/>
                    <Route
                        exact
                        path="/services"
                        render={(props) => <Services
                            {...props}
                            setValue={setValue}
                            setSelectedIndex={setSelectedIndex}/>}
                    />
                    <Route
                        exact
                        path="/contact"
                        render={(props) => <Contact
                            {...props}
                            setValue={setValue}
                            setSelectedIndex={setSelectedIndex}/>}
                    />
                    <Route
                        exact
                        path="/customsoftware"
                        render={(props) => <CustomSoftware
                            {...props}
                            setValue={setValue}
                            setSelectedIndex={setSelectedIndex}/>}
                    />
                    <Route
                        exact
                        path="/pohodaservis"
                        render={(props) => <PohodaServis
                            {...props}
                            setValue={setValue}
                            setSelectedIndex={setSelectedIndex}/>}
                    />
                    <Route
                        exact
                        path="/mobileapps"
                        render={(props) => <MobileApps
                            {...props}
                            setValue={setValue}
                            setSelectedIndex={setSelectedIndex}/>}
                    />
                    <Route
                        exact
                        path="/websites"
                        render={(props) => <Websites
                            {...props}
                            setValue={setValue}
                            setSelectedIndex={setSelectedIndex}/>}
                    />
                    <Route
                        exact
                        path="/firmyservis"
                        render={(props) => <FirmyServis
                            {...props}
                            setValue={setValue}
                            setSelectedIndex={setSelectedIndex}/>}
                    />
                    <Route
                        exact
                        path="/revolution"
                        render={(props) => <Revolutions
                            {...props}
                            setValue={setValue}
                            setSelectedIndex={setSelectedIndex}/>}
                    />

                    <Route
                        exact
                        path="/aspedsql"
                        component={() => <div>A-SPED SQl</div>}
                    />

                    <Route
                        exact
                        path="/estimate"
                        render={(props) => <Estimate
                            {...props}
                            setValue={setValue}
                            setSelectedIndex={setSelectedIndex}/>}
                    />
                </Switch>
                <Footer
                    setValue={setValue}
                    setSelectedIndex={setSelectedIndex}
                />
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
