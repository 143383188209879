import React, {useState} from "react";
import axios from 'axios';
import {cloneDeep} from 'lodash';
import {makeStyles, useTheme} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Lottie from 'react-lottie';

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Hidden from "@material-ui/core/Hidden";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import check from '../assets/check.svg';
import send from '../assets/send.svg';
import software from '../assets/software.svg';
import mobile from '../assets/mobile.svg';
import website from '../assets/website.svg';
import backArrow from '../assets/backArrow.svg';
import backArrowDisabled from '../assets/backArrowDisabled.svg';
import forwardArrow from '../assets/forwardArrow.svg';
import forwardArrowDisabled from '../assets/forwardArrowDisabled.svg';
import camera from '../assets/camera.svg';
import upload from '../assets/upload.svg';
import person from '../assets/person.svg';
import persons from '../assets/persons.svg';
import people from '../assets/people.svg';
import info from '../assets/info.svg';
import users from '../assets/users.svg';
import iphone from '../assets/iphone.svg';
import gps from '../assets/gps.svg';
import customized from '../assets/customized.svg';
import data from '../assets/data.svg';
import android from '../assets/android.svg';
import globe from '../assets/globe.svg';
import biometrics from '../assets/biometrics.svg';
import bell from '../assets/bell.svg';

import estimateAnimation from '../animations/estimateAnimation/data.json';

const useStyles = makeStyles(theme => ({
    icon: {
        width: "12em",
        height: "10em"
    },
    message: {
        border: `2px solid ${theme.palette.common.orkaBlue}`,
        marginTop: "3em",
        marginBottom: "2em",
        borderRadius: 5
    },
    specialText: {
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: "1.5rem",
        color: theme.palette.common.orkaOrange
    },

    estimateButton: {
        ...theme.typography.estimate,
        borderRadius: 50,
        backgroundColor: theme.palette.common.orkaOrange,
        height: 50,
        width: 225,
        fontSize: "1.25rem",
        marginTop: "5em",
        "&:hover": {
            backgroundColor: theme.palette.secondary.light
        }
    },


}))

const defaultQuestions = [
    {
        id: 1,
        title: "Jaká služba vás zajímá?",
        active: true,
        options: [
            {
                id: 1,
                title: "Uživatelský software",
                subtitle: null,
                icon: software,
                iconAlt: "software icon",
                selected: false,
                cost: 0
            },
            {
                id: 2,
                title: "Mobilní aplikace",
                subtitle: null,
                icon: mobile,
                iconAlt: "mobile icon",
                selected: false,
                cost: 0
            },
            {
                id: 3,
                title: "Webové stránky",
                subtitle: null,
                icon: website,
                iconAlt: "websites icon",
                selected: false,
                cost: 0
            },
            {
                id: 4,
                title: "Servis firemní IT",
                subtitle: null,
                icon: software,
                iconAlt: "itservis icon",
                selected: false,
                cost: 0
            }
        ]
    }
]

const softwareQuestions = [
    {...defaultQuestions[0], active: false},
    {
        id: 2,
        title: "Které platformy potřebujete podporovat?",
        subtitle: "Vybrat vše, co platí.",
        options: [
            {
                id: 1,
                title: "Webové aplikace",
                subtitle: null,
                icon: website,
                iconAlt: "computer outline",
                selected: false,
                cost: 2700
            },
            {
                id: 2,
                title: "iOS Aplikace",
                subtitle: null,
                icon: iphone,
                iconAlt: "outline of iphone",
                selected: false,
                cost: 2700
            },
            {
                id: 3,
                title: "Android Aplikace",
                subtitle: null,
                icon: android,
                iconAlt: "outlines of android phone",
                selected: false,
                cost: 2700
            }
        ],
        active: true
    },
    {
        id: 3,
        title: "Jaké funkce očekáváte?",
        subtitle: "Vyberte vše, co potřebujete.",
        options: [
            {
                id: 1,
                title: "Photo/Video",
                subtitle: null,
                icon: camera,
                iconAlt: "camera outline",
                selected: false,
                cost: 700
            },
            {
                id: 2,
                title: "GPS",
                subtitle: null,
                icon: gps,
                iconAlt: "gps pin",
                selected: false,
                cost: 700
            },
            {
                id: 3,
                title: "File Transfer",
                subtitle: null,
                icon: upload,
                iconAlt: "outline of cloud with arrow pointing up",
                selected: false,
                cost: 700
            }
        ],
        active: false
    },
    {
        id: 4,
        title: "Jaké funkce očekáváte?",
        subtitle: "Vyberte vše, co potřebujete.",
        options: [
            {
                id: 1,
                title: "Users/Authentication",
                subtitle: null,
                icon: users,
                iconAlt: "outline of a person with a plus sign",
                selected: false,
                cost: 700
            },
            {
                id: 2,
                title: "Biometrics",
                subtitle: null,
                icon: biometrics,
                iconAlt: "fingerprint",
                selected: false,
                cost: 700
            },
            {
                id: 3,
                title: "Push Notifications",
                subtitle: null,
                icon: bell,
                iconAlt: "outline of a bell",
                selected: false,
                cost: 700
            }
        ],
        active: false
    },
    {
        id: 5,
        title: "Jaký typ uživatelské funkčnosti očekáváte?",
        subtitle: "Vyberte jednu.",
        options: [
            {
                id: 1,
                title: "Nízká složitost",
                subtitle: "(Informativní)",
                icon: info,
                iconAlt: "'i' inside a circle",
                selected: false,
                cost: 700
            },
            {
                id: 2,
                title: "Střední složitost",
                subtitle: "(Interaktivní, konfigurovatelné, v reálném čase)",
                icon: customized,
                iconAlt: "two toggle switches",
                selected: false,
                cost: 700
            },
            {
                id: 3,
                title: "Vysoká složitost",
                subtitle: "(Modelování a výpočet dat)",
                icon: data,
                iconAlt: "outline of line graph",
                selected: false,
                cost: 2700
            }
        ],
        active: false
    },
    {
        id: 6,
        title: "Kolik uživatelů předpokládáte?",
        subtitle: "Vyberte jednu.",
        options: [
            {
                id: 1,
                title: "0-10",
                subtitle: null,
                icon: person,
                iconAlt: "person outline",
                selected: false,
                cost: 1
            },
            {
                id: 2,
                title: "10-100",
                subtitle: null,
                icon: persons,
                iconAlt: "outline of two people",
                selected: false,
                cost: 1.25
            },
            {
                id: 3,
                title: "100+",
                subtitle: null,
                icon: people,
                iconAlt: "outline of three people",
                selected: false,
                cost: 1.5
            }
        ],
        active: false
    }
];

const websiteQuestions = [
    {...defaultQuestions[0], active: false},
    {
        id: 2,
        title: "Jaký typ webu chcete?",
        subtitle: "Vyberte jednu.",
        options: [
            {
                id: 1,
                title: "Základní",
                subtitle: "(informativní)",
                icon: info,
                iconAlt: "person outline",
                selected: false,
                cost: 15000
            },
            {
                id: 2,
                title: "Interaktivní",
                subtitle: "(Uživatelé, API's, Zprávy)",
                icon: customized,
                iconAlt: "outline of two people",
                selected: false,
                cost: 25000
            },
            {
                id: 3,
                title: "E-Commerce",
                subtitle: "(Prodejní, E-shop)",
                icon: globe,
                iconAlt: "outline of three people",
                selected: false,
                cost: 45000
            }
        ],
        active: true
    }
];

export default function Estimate(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))

    const [questions, setQuestions] = useState(defaultQuestions);

    const [dialogOpen, setDialogOpen] = useState(false)

    const [name, setName] = useState("");

    const [email, setEmail] = useState("");
    const [emailHelper, setEmailHelper] = useState("");

    const [phone, setPhone] = useState("");
    const [phoneHelper, setPhoneHelper] = useState("");

    const [message, setMessage] = useState("");

    const [total, setTotal] = useState(0);

    const [service, setService] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [features, setFeatures] = useState([]);
    const [customFeatures, setCustomFeatures] = useState("");
    const [category, setCategory] = useState("");
    const [users, setUsers] = useState("");

    const [loading, setLoading] = useState(false);

    const [alert, setAlert] = useState({open: false, message: "", backroundColor: ""});

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: estimateAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const nextQuestion = () => {
        const newQuestions = cloneDeep(questions);
        const currentlyActive = newQuestions.filter(question => question.active);
        const activeIndex = currentlyActive[0].id - 1;
        const nextIndex = activeIndex + 1;

        newQuestions[activeIndex] = {...currentlyActive[0], active: false};
        newQuestions[nextIndex] = {...newQuestions[nextIndex], active: true};

        setQuestions(newQuestions);
    };

    const previousQuestion = () => {
        const newQuestions = cloneDeep(questions);
        const currentlyActive = newQuestions.filter(question => question.active);
        const activeIndex = currentlyActive[0].id - 1;
        const nextIndex = activeIndex - 1;

        newQuestions[activeIndex] = {...currentlyActive[0], active: false};
        newQuestions[nextIndex] = {...newQuestions[nextIndex], active: true};

        setQuestions(newQuestions);
    };

    const navigationPreviousDisabled = () => {
        const currentlyActive = questions.filter(question => question.active);
        if (currentlyActive[0].id === 1) {
            return true
        } else {
            return false;
        }
    }

    const navigationNextDisabled = () => {
        const currentlyActive = questions.filter(question => question.active);
        if (currentlyActive[0].id === questions[questions.length - 1].id) {
            return true
        } else {
            return false;
        }
    }

    const handleSelect = (id) => {
        const newQuestions = cloneDeep(questions);
        const currentlyActive = newQuestions.filter(question => question.active);
        const activeIndex = currentlyActive[0].id - 1;

        const newSelected = newQuestions[activeIndex].options[id - 1]
        const previousSelected = currentlyActive[0].options.filter(option => option.selected)

        const clearSelection = () => {
            setPlatforms([]);
            setFeatures([]);
            setCustomFeatures("");
            setCategory("");
            setUsers("");

        }

        switch (currentlyActive[0].subtitle) {
            case 'Vyberte jednu.':
                if (previousSelected[0]) {
                    previousSelected[0].selected = !previousSelected[0].selected
                }
                newSelected.selected = !newSelected.selected;
                break;
            default:
                newSelected.selected = !newSelected.selected;
                break;
        }

        switch (newSelected.title) {
            case 'Uživatelský software':
                setQuestions(softwareQuestions)
                setService(newSelected.title)
                clearSelection();
                break
            case 'Mobilní aplikace':
                setQuestions(softwareQuestions)
                setService(newSelected.title)
                clearSelection();
                break
            case 'Webové stránky':
                setQuestions(websiteQuestions)
                setService(newSelected.title)
                clearSelection();
                break
            default:
                setQuestions(newQuestions)
                break
        }
    }

    const onChange = (event) => {
        let valid;
        switch (event.target.id) {
            case 'email':
                setEmail(event.target.value)
                valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)

                if (!valid) {
                    setEmailHelper("Nesprávný formát emailu")
                } else {
                    setEmailHelper("")
                }
                break;
            case 'phone':
                setPhone(event.target.value)
                valid = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/.test(event.target.value)

                if (!valid) {
                    setPhoneHelper("Nesprávný formát telefonu")
                } else {
                    setPhoneHelper("")
                }
                break;

            default:
                break;
        }
    }

    const getTotal = () => {
        let cost = 0;

        const selections = questions.map(question => question.options.filter(option => option.selected)).filter(question => question.length > 0);

        selections.map(options => options.map(option => (cost += option.cost)));

        if (questions.length > 2) {
            const userCost = questions.filter(
                (question => question.title === 'Kolik uživatelů předpokládáte?')).map(question => question.options.filter(option => option.selected))[0][0]
            // console.log(userCost)
            setUsers(userCost.title);

            cost -= userCost.cost;
            cost *= userCost.cost;
        }

        setTotal(cost);
    }

    const getPlatforms = () => {
        let newPlatforms = [];

        if (questions.length > 2) {
            questions.filter(question => question.title === "Které platformy potřebujete podporovat?").map(question => question.options.filter(option =>
                option.selected))[0].map(option => newPlatforms.push(option.title))

            setPlatforms(newPlatforms);
        }
    }

    const getFeatures = () => {
        let newFeatures = [];

        if (questions.length > 2) {
            questions.filter(question => question.title === "Jaké funkce očekáváte?").map(question => question.options.filter(option =>
                option.selected)).map(option => option.map(newFeature => newFeatures.push(newFeature.title)))

            setFeatures(newFeatures);
        }
    }

    const getCustomFeatures = () => {
        if (questions.length > 2) {
            const newCustomFeatures = questions.filter(question => question.title === "Jaký typ uživatelské funkčnosti očekáváte?").map(question => question.options.filter(option => option.selected))[0][0].title

            setCustomFeatures(newCustomFeatures);
        }
    }

    const getCategory = () => {
        if (questions.length === 2) {
            const newCategory = questions.filter(question => question.title === "Jaký typ webu chcete?")[0].options.filter(option => option.selected)[0].title;

            setCategory(newCategory);
        }
    }

    const sendEstimate = () => {
        setLoading(true);

        axios.get("https://us-central1-orka-solutions-web-d9c97.cloudfunctions.net/sendMail", {
            params: {
                name: name,
                email: email,
                phone: phone,
                message: message,
                total: total,
                category: category,
                service: service,
                platforms: platforms,
                features: features,
                customFeatures: customFeatures,
                users: users
            }
        })
            .then(res => {
                setLoading(false);
                // setName("");
                // setEmail("");
                // setPhone("");
                // setMessage("");
                setAlert({open: true, message: "Poptávka úspěšně odeslána", backroundColor: "#4BB543"});
                setDialogOpen(false);
            })
            .catch(err => {
                console.error(err)
                setLoading(false);
                setAlert({open: true, message: "Něco se pokazilo, zkuste to znova", backroundColor: "#FF3232"})
            });
    }

    const estimateDisabled = () => {
        let disabled = true;

        const emptySelectons = questions.map(question => question.options.filter(option => option.selected)).filter(question => question.length === 0);

        if (questions.length === 2) {
            if (emptySelectons.length === 1) {
                disabled = false;
            }
        } else if (questions.length === 1) {
            disabled = true;
        } else if (emptySelectons.length < 3 &&
            questions[questions.length - 1].options.filter(option => option.selected).length > 0) {
            disabled = false
        }

        return disabled;
    };

    const softwareSelection = (
        <Grid container direction={"column"}>
            <Grid item container alignItems={"center"} style={{marginBottom: "1.25em"}}>
                <Grid item xs={2}>
                    <img src={check} alt={"check icon"}/>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant={"body1"}>
                        Požadujete {service}
                        {platforms.length > 0 ?
                            ` ${
                                //if only web application is selected...
                                platforms.indexOf("Webové aplikace") > -1 &&
                                platforms.length === 1
                                    ? //then finish sentence here
                                    "pro Webové aplikace."
                                    : //otherwise, if web application and another platform is selected...
                                    platforms.indexOf("Webové aplikace") > -1 &&
                                    platforms.length === 2
                                        ? //then finish the sentence here
                                        `pro Webové aplikace a pro ${platforms[1]}.`
                                        : //otherwise, if only one platform is selected which isn't web application...
                                        platforms.length === 1
                                            ? //then finish the sentence here
                                            `pro ${platforms[0]}`
                                            : //otherwise, if other two options are selected...
                                            platforms.length === 2
                                                ? //then finish the sentence here
                                                "pro iOS Aplikace a pro Android Aplikace."
                                                : //otherwise if all three are selected...
                                                platforms.length === 3
                                                    ? //then finish the sentence here
                                                    "pro Web Aplikace, iOS Aplikace, a pro Android Aplikace."
                                                    : null
                            }`
                            : null}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container alignItems={"center"} style={{marginBottom: "1.25em"}}>
                <Grid item xs={2}>
                    <img src={check} alt={"check icon"}/>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant={"body1"}>
                        {"s "}
                        {/* if we have features... */}
                        {features.length > 0
                            ? //...and there's only 1...
                            features.length === 1
                                ? //then end the sentence here
                                `${features[0]}.`
                                : //otherwise, if there are two features...
                                features.length === 2
                                    ? //...then end the sentence here
                                    `${features[0]} a ${features[1]}.`
                                    : //otherwise, if there are three or more features...
                                    features
                                        //filter out the very last feature...
                                        .filter(
                                            (feature, index) =>
                                                index !== features.length - 1
                                        )
                                        //and for those features return their name...
                                        .map((feature, index) => (
                                            <span key={index}>{`${feature}, `}</span>
                                        ))
                            : null}
                        {features.length > 2
                            ? //...and then finally add the last feature with 'and' in front of it
                            ` a ${features[features.length - 1]}.`
                            : null}
                    </Typography>
                </Grid>

            </Grid>
            <Grid item container alignItems={"center"}>
                <Grid item xs={2}>
                    <img src={check} alt={"check icon"}/>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant={"body1"}>
                        Požadovaná uživatelská funkčnost je:
                        {customFeatures.toLocaleLowerCase()}
                        {`, a projekt bude využívat ${users} uživatelů`}
                    </Typography>
                </Grid>

            </Grid>
        </Grid>
    )

    const websiteSelection = (
        <Grid container direction={"column"} style={{marginTop: "14em"}}>
            <Grid item container alignItems={"center"}>
                <Grid item xs={2}>
                    <img src={check} alt={"checkmark"}/>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant={"body1"}>
                        Požadujete {category} funkčnost webových stránek
                    </Typography>

                </Grid>
            </Grid>

        </Grid>
    )

    const buttonContent = (
        <React.Fragment>
            Odeslat poptávku
            <img src={send} alt={'send ikon'} style={{marginLeft: "0.5em"}}/>
        </React.Fragment>
    )

    return (
        <Grid container direction="row">
            <Grid item container direction="column" lg alignItems={matchesMD ? "center" : undefined}>
                <Grid item style={{marginTop: "2em", marginLeft: matchesMD ? 0 : "5em"}}>
                    <Typography variant="h2" align={matchesMD ? "center" : undefined}>
                        Poptávka
                    </Typography>
                </Grid>
                <Grid item style={{
                    marginRight: matchesMD ? 0 : "10em",
                    maxWidth: "50em",
                    marginBottom: "2.5em",
                    marginTop: "5em"
                }}>
                    <Lottie
                        options={defaultOptions}
                        height={"100%"}
                        width={"100%"}
                    />
                </Grid>
            </Grid>
            <Grid
                item
                container
                direction="column"
                alignItems={"center"}
                lg
                style={{marginRight: matchesMD ? 0 : "2em", marginBottom: "25em"}}
            >
                {questions.filter(question => question.active).map((question, index) => (
                    <React.Fragment key={index}>
                        <Grid item>
                            <Typography
                                variant={"h2"}
                                align={"center"}
                                style={
                                    {
                                        fontWeight: 500,
                                        fontSize: "2.25em",
                                        marginTop: "5em",
                                        lineHeight: 1.25,
                                        marginLeft: matchesSM ? "1em" : 0,
                                        marginRight: matchesSM ? "1em" : 0
                                    }
                                }
                            >
                                {question.title}
                            </Typography>
                            <Typography variant={"body1"} align={"center"} style={{marginBottom: "2.5em"}} gutterBottom>
                                {question.subtitle}
                            </Typography>
                        </Grid>
                        <Grid item container>
                            {question.options.map(option => (
                                <Grid
                                    key={option.id}
                                    item
                                    container
                                    direction={"column"}
                                    md
                                    component={Button}
                                    onClick={() => handleSelect(option.id)}
                                    style={
                                        {
                                            borderRadius: 0,
                                            display: "grid",
                                            textTransform: "none",
                                            marginBottom: matchesSM ? "1.5em" : 0,
                                            backgroundColor: option.selected ? theme.palette.common.orkaOrange : null
                                        }
                                    }
                                >
                                    <Grid item style={{maxWidth: "14em"}}>
                                        <Typography
                                            variant={"h6"}
                                            align={"center"}
                                            style={{marginBottom: "1em"}}>
                                            {option.title}
                                        </Typography>
                                        <Typography variant={"caption"} align={"center"}>
                                            {option.subtitle}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <img
                                            src={option.icon}
                                            alt={option.iconAlt}
                                            className={classes.icon}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </React.Fragment>
                ))}


                <Grid
                    item
                    container
                    justify="space-between"
                    style={{width: "18em", marginTop: "2em"}}>
                    <Grid item>
                        <IconButton onClick={previousQuestion} disabled={navigationPreviousDisabled()}>
                            <img src={navigationPreviousDisabled() ? backArrowDisabled : backArrow}
                                 alt={"Predchozi otazka"}/>
                        </IconButton>

                    </Grid>
                    <Grid item>
                        <IconButton onClick={nextQuestion} disabled={navigationNextDisabled()}>
                            <img src={navigationNextDisabled() ? forwardArrowDisabled : forwardArrow}
                                 alt={"Dalsi otazka"}/>
                        </IconButton>

                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        variant={"contained"}
                        className={classes.estimateButton}
                        disabled={estimateDisabled()}
                        onClick={() => {
                            setDialogOpen(true);
                            getTotal();
                            getPlatforms();
                            getFeatures();
                            getCustomFeatures();
                            getCategory();
                        }}>
                        Zadat Poptávku
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                fullWidth
                maxWidth={"lg"}
                fullScreen={matchesSM}
                style={{zIndex: 1302}}
            >
                <Grid container justify={"center"} style={{marginTop: "1em", marginBottom: "1em"}}>
                    <Typography variant={"h2"} align={"center"}>
                        Poptávka
                    </Typography>
                </Grid>
                {/* --- Dialog obsah --- */}
                <DialogContent>
                    <Grid
                        container justify={"space-around"} direction={matchesSM ? "column" : "row"}
                          alignItems={matchesSM ? "center" : undefined}>
                        <Grid item container direction={"column"} md={7} style={{maxWidth: "20em"}}>
                            <Grid item style={{marginBottom: "0.5em"}}>
                                <TextField
                                    fullWidth
                                    label={"Jméno"}
                                    id={"name"}
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}/>
                            </Grid>
                            <Grid item style={{marginBottom: "0.5em"}}>
                                <TextField
                                    fullWidth
                                    error={emailHelper.length !== 0}
                                    helperText={emailHelper}
                                    label={"Email"}
                                    id={"email"} value={email}
                                    onChange={onChange}/>
                            </Grid>
                            <Grid item style={{marginBottom: "0.5em"}}>
                                <TextField
                                    fullWidth
                                    label={"Telefon"}
                                    error={phoneHelper.length !== 0}
                                    helperText={phoneHelper}
                                    id={"phone"}
                                    value={phone}
                                    onChange={onChange}/>
                            </Grid>
                            <Grid item style={{maxWidth: "20em"}}>
                                <TextField
                                    InputProps={{disableUnderline: true}}
                                    className={classes.message}
                                    fullWidth
                                    multiline
                                    placeholder={"Povězte nám něco o vašem projektu"}
                                    rows={5}
                                    value={message}
                                    id={"message"}
                                    onChange={(event) => setMessage(event.target.value)}/>

                            </Grid>
                            <Grid item>
                                <Typography
                                    variant={"body1"}
                                    align={matchesSM ? "center" : undefined}
                                    paragraph
                                    style={{lineHeight: 1.25}}
                                >
                                    Můžeme vytvořit odhad pro tato digitální řešení <span
                                    className={classes.specialText}>{total.toFixed(2)}</span>
                                </Typography>
                                <Typography variant={"body1"} align={matchesSM ? "center" : undefined} paragraph>
                                    Vyplňte své jméno, telefon, email a my vás budeme kontaktovat pro doplnění detailů,
                                    které pomohou vytvořit konečnou cenu
                                </Typography>
                            </Grid>


                        </Grid>
                        <Grid
                            item
                            container
                            direction={"column"}
                            alignItems={matchesSM ? "center" : undefined}
                            md={5}
                            style={{maxWidth: "30em"}}
                        >
                            <Hidden mdDown>
                                <Grid item>
                                    {questions.length > 2 ? softwareSelection : websiteSelection}
                                </Grid>
                            </Hidden>
                            <Grid item>
                                <Button
                                    variant={"contained"}
                                    className={classes.estimateButton}
                                    onClick={sendEstimate}
                                    disabled={name.length === 0 || message.length === 0 || phoneHelper.length !== 0 || emailHelper.length !== 0}
                                >
                                    {loading ? <CircularProgress size={30}/> : buttonContent}
                                </Button>
                            </Grid>
                            <Hidden mdUp>
                                <Grid item style={{marginBottom: matchesSM ? "5em" : 0}}>
                                    <Button style={{fontWeight: 300}} color={"primary"}
                                            onClick={() => setDialogOpen(false)}>
                                        Storno
                                    </Button>
                                </Grid>
                            </Hidden>

                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={alert.open}
                message={alert.message}
                ContentProps={
                    {
                        style: {
                            backgroundColor: alert.backroundColor
                        }
                    }}
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                onClose={() => setAlert({...alert, open: false})}
                autoHideDuration={4000}
            />

        </Grid>
    )
}
