import React from 'react';
import {Link} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles, useTheme} from "@material-ui/styles";
import Button from '@material-ui/core/Button';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import background from '../../assets/background.jpg';
import mobileBackground from '../../assets/mobileBackground.jpg';
import ReadMoreButton from "./buttons/ReadMoreButton";


const useStyles = makeStyles(theme => ({
    background: {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        height: "60em",
        width: "100%",
        [theme.breakpoints.down("md")]: {
            backgroundImage: `url(${mobileBackground})`,
            backgroundAttachment: "inherit"
        }
    },
    estimateButton: {
        ...theme.typography.estimate,
        borderRadius: 50,
        height: 80,
        width: 205,
        backgroundColor: theme.palette.common.orkaOrange,
        fontSize: "1.5rem",
        marginRight: "5em",
        marginLeft: "2em",
                "&:hover": {
            backgroundColor: theme.palette.secondary.light,
        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            marginRight: 0
        }

    }
}));

export default function CallToAction(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matchedSM = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Grid
            container
            className={classes.background}
            style={{height: "60em"}}
            alignItems="center"
            justify={matchedSM ? "center" : "space-between"}
            direction={matchedSM ? "column":"row"}
        >
            <Grid
                item
                style={{marginLeft: matchedSM ? 0: "5em", textAlign: matchedSM ? "center":"inherit"}} >
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="h2">
                            Jednoduchý software. <br/>Revoluční výsledky
                        </Typography>
                        <Typography variant="subtitle2" style={{fontSize: "1.5rem"}}>
                            Využijte výhod technologií 21.století
                        </Typography>
                        <Grid container item justify={matchedSM ? "center": undefined}>
                            <ReadMoreButton link="/revolution" onclick={() => props.setValue(2)} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item >
                <Button
                    variant="contained"
                    className={classes.estimateButton}
                    component={Link} to={'/estimate'}
                    onClick={() => props.setValue(5)}
                >
                    Poptávka
                </Button>

            </Grid>
        </Grid>
    )
}

