import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavArrowButton from "./UI/buttons/NavArrowButton";
import lightbulbicon from '../assets/bulb.svg';
import cashicon from '../assets/cash.svg';
import stopwatchicon from '../assets/stopwatch.svg';

const useStyles = makeStyles(theme => ({
    mainContainer: {
        paddingLeft: "5em",
        paddingRight: "5em",
        paddingTop: "2em",
        paddingBottom: "10em"
    },
    arrowContainer: {
        marginTop: "0.5em"
    },
    heading: {
        maxWidth: "40em"
    }
}))

export default function PohodaServis(props) {
    const classes = useStyles()

    return (
        <Grid container direction="column" className={classes.mainContainer}>
            <Grid item container direction="row">
                <Grid item className={classes.arrowContainer} style={{marginRight: "1em", marginLeft: "-3.5em"}}>
                    <NavArrowButton direct="back" link="/services" onclick={() => props.setSelectedIndex(0)}/>
                </Grid>
                <Grid item container direction="column" className={classes.heading}>
                    <Grid item>
                        <Typography variant="h2">Administrace Stormware POHODA</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" paragraph>
                            Náklady na mzdy, vybavení a školení správců a administrátorů jsou značné a také neustále
                            rostou. Zbavte se této položky v rozpočtu a využijte externí správy sítě
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Používáme moderní technologie a automatizujeme Kombinujeme výhody automatizace
                            (rychlost, přesnost, nízká cena) a lidské práce (vysoká odbornost, kreativita, lidský
                            přístup). Vy získáte vysoce odborné lidi za rozumnou cenu bez kompromisů na straně
                            kvality.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Náklady na mzdy, vybavení a školení správců a administrátorů jsou značné a také neustále
                            rostou. Zbavte se této položky v rozpočtu a využijte externí správy sítě
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Náklady na mzdy, vybavení a školení správců a administrátorů jsou značné a také neustále
                            rostou. Zbavte se této položky v rozpočtu a využijte externí správy sítě
                        </Typography>
                    </Grid>

                </Grid>

                <Grid item className={classes.arrowContainer}>
                    <NavArrowButton direct="fw" link="/firmyservis" onclick={() => props.setSelectedIndex(0)}/>
                </Grid>
            </Grid>
            <Grid item container direction="row" justify="center" style={{marginTop: "15em", marginBottom: "20em"}}>
                <Grid item container direction="column" md alignItems="center" style={{maxWidth: "40em"}}>
                    <Grid item>
                        <Typography variant="h4">
                            Šetřete energii
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img src={lightbulbicon} alt="save energy icon"/>
                    </Grid>
                </Grid>
                <Grid item container direction="column" md alignItems="center" style={{maxWidth: "40em"}}>
                    <Grid item>
                        <Typography variant="h4">
                            Šetřete peníze
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img src={cashicon} alt="save energy icon"/>
                    </Grid>
                </Grid>
                <Grid item container direction="column" md alignItems="center" style={{maxWidth: "40em"}}>
                    <Grid item>
                        <Typography variant="h4">
                            Šetřete čas
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img src={stopwatchicon} alt="save energy icon"/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="row">
                <Grid item container>
                    <Grid item container direction="column">
                        <Grid item>
                            <Typography variant="h4">

                            </Typography>

                        </Grid>

                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    )
}
