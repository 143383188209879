import React from 'react';
import {Link} from 'react-router-dom';
import {makeStyles, useTheme} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ButtonArrow from "./UI/buttons/ButtonArrow";
import customSoftware from "../assets/Custom Software Icon.svg";
import mobileAppIcon from "../assets/mobileIcon.svg";
import websideIcon from "../assets/websiteIcon.svg";

const useStyles = makeStyles(theme => ({
    servicesContainer: {
        marginTop: "10em",
        [theme.breakpoints.down("sm")]: {
            padding: 25
        }
    },
    icon: {
        marginLeft: "2em",
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0
        },
        height: "150px",
        width: "150px"
    },
    subtitle: {
        marginBottom: "1em"
    },
    learnButton: {
        ...theme.typography.learnButton,
        fontSize: "0.7rem",
        height: 35,
        padding: 5,
        [theme.breakpoints.down("sm")]: {
            marginBottom: "2em"
        }
    },
    specialText: {
        fontFamily: "Pacifico",
        color: theme.palette.common.orkaOrange,
    },


}))

export default function Services(props) {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Grid container direction="column">
            {/*----- Nadpis Block -----*/}
            <Grid item style={{marginLeft: matchesSM ? 0 : "5em", marginTop: matchesSM ? "1em" : "2em"}}>
                <Typography variant={"h2"} gutterBottom align={matchesSM ? "center" : undefined} >
                    Služby
                </Typography>
            </Grid>
            {/*----- Custom Software Block -----*/}
            <Grid item>
                <Grid
                    container direction="row"
                    className={classes.servicesContainer}
                    justify={matchesSM ? "center" : "flex-end"}
                    style={{
                        marginTop: matchesSM ? "1em" : "5em",
                    }}
                >
                    <Grid
                        item
                        style={
                            {
                                textAlign: matchesSM ? "center" : undefined,
                                width: matchesSM ? undefined : "35em"
                            }
                        }>
                        <Typography variant="h4">
                            Software na zakázku
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            Šetřete energii. Šetřete čas. Šetřete peníze
                        </Typography>
                        <Typography variant="subtitle1">
                            Kompletní digitální řešení od průzkumu po <span className={classes.specialText}>oslavu</span>.
                        </Typography>
                        <Button
                            variant="outlined"
                            className={classes.learnButton}
                            component={Link} to={'/customsoftware'}
                            onClick={() => {
                                props.setValue(2);
                                props.setSelectedIndex(1)
                            }}
                        >
                            <span style={{marginRight: 10}}>Více</span>
                            <ButtonArrow
                                height="10"
                                width="10"
                                fill={theme.palette.common.orkaBlue}
                            />
                        </Button>
                    </Grid>
                    <Grid item>
                        <img
                            className={classes.icon}
                            style={{marginRight: matchesSM ? 0 : "5em"}}
                            alt="teamviewer logo"
                            src={customSoftware}
                            width={"250em"}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/*----- Mobile Apps Block -----*/}
            <Grid item>
                <Grid
                    container direction="row"
                    className={classes.servicesContainer}
                    justify={matchesSM ? "center" : undefined}
                >
                    <Grid item style={
                        {
                            marginLeft: matchesSM ? 0 : "5em",
                            textAlign: matchesSM ? "center" : undefined,
                            width: matchesSM ? undefined : "35em"
                        }
                    }
                    >
                        <Typography variant="h4">
                            iOS/Android programování aplikací
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            Rozšiřte funkčnost. Rozšířit přístup. Zvýšení zapojení.
                        </Typography>
                        <Typography variant="subtitle1">
                            Integrujte své webové prostředí nebo vytvořte samostatnou aplikaci buď s mobilní platformou.
                        </Typography>
                        <Button
                            variant="outlined"
                            className={classes.learnButton}
                            component={Link} to={'/mobileapps'}
                            onClick={() => {
                                props.setValue(2);
                                props.setSelectedIndex(2)
                            }}

                        >
                            <span style={{marginRight: 10}}>Více</span>
                            <ButtonArrow
                                height="10"
                                width="10"
                                fill={theme.palette.common.orkaBlue}
                            />
                        </Button>
                    </Grid>
                    <Grid
                        item
                        style={{marginRight: matchesSM ? 0 : "5em"}}
                    >
                        <img className={classes.icon} alt="mobile app logo" src={mobileAppIcon}/>
                    </Grid>
                </Grid>
            </Grid>
            {/*----- Web Side Block -----*/}
            <Grid item>
                <Grid
                    container direction="row"
                    className={classes.servicesContainer}
                    justify={matchesSM ? "center" : "flex-end"}
                >
                    <Grid
                        item
                        style=
                            {
                                {
                                    textAlign: matchesSM ? "center" : undefined,
                                    width: matchesSM ? undefined : "35em"

                                }
                            }
                    >
                        <Typography variant="h4">
                            Web Design
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            Dosáhnout více. Objevit více. Prodat více
                        </Typography>
                        <Typography variant="subtitle1">
                            Optimalizováno pro vyhledávače. Tvořeno pro rychlost.
                        </Typography>
                        <Button
                            variant="outlined"
                            className={classes.learnButton}
                            component={Link} to={'/websites'}
                            onClick={() => {
                                props.setValue(2);
                                props.setSelectedIndex(3)
                            }}

                        >
                            <span style={{marginRight: 10}}>Více</span>
                            <ButtonArrow
                                height="10"
                                width="10"
                                fill={theme.palette.common.orkaBlue}
                            />
                        </Button>
                    </Grid>
                    <Grid
                        item
                    >
                        <img
                            className={classes.icon}
                            style={{marginRight: matchesSM ? 0 : "5em"}}
                            alt="web side logo"
                            src={websideIcon}/>
                    </Grid>
                </Grid>
            </Grid>
            {/*----- IT Servis Block -----*/}
            <Grid item>
                <Grid
                    container direction="row"
                    className={classes.servicesContainer}
                    justify={matchesSM ? "center" : undefined}
                    style={{
                        marginBottom: "10em"
                    }}
                >
                    <Grid item style={
                        {
                            marginLeft: matchesSM ? 0 : "5em",
                            textAlign: matchesSM ? "center" : undefined,
                            width: matchesSM ? undefined : "35em"
                        }
                    }
                    >
                        <Typography variant="h4">
                            Servis firemní IT
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitle}>
                            Náklady na mzdy, vybavení a školení správců a administrátorů jsou značné <br/> a také
                            neustále rostou
                        </Typography>
                        <Typography variant="subtitle1">
                            Zbavte se této položky v rozpočtu a využijte naší nabídky externí správy sítě.
                        </Typography>
                        <Button
                            variant="outlined"
                            className={classes.learnButton}
                            component={Link} to={'/firmyservis'}
                            onClick={() => {
                                props.setValue(2);
                                props.setSelectedIndex(2)
                            }}

                        >
                            <span style={{marginRight: 10}}>Více</span>
                            <ButtonArrow
                                height="10"
                                width="10"
                                fill={theme.palette.common.orkaBlue}
                            />
                        </Button>
                    </Grid>
                    <Grid
                        item
                        style={{marginRight: matchesSM ? 0 : "5em"}}
                    >
                        <img className={classes.icon} alt="mobile app logo" src={mobileAppIcon}/>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}