import React, {useState} from "react";
import axios from 'axios';
import {makeStyles, useTheme} from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";

import Typography from "@material-ui/core/Typography";

import background from '../assets/background.jpg';
import phoneIcon from '../assets/phone.svg';
import emailIcon from '../assets/email.svg';
import airplane from '../assets/send.svg';

import CallToAction from "./UI/CallToAction";

// import infoBackground from "../assets/infoBackground.svg";

const useStyles = makeStyles(theme => ({
    background: {
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "60em",
    },
    message: {
        border: `2px solid ${theme.palette.common.orkaBlue}`,
        marginTop: "5em",
        borderRadius: 5
    },
    sendButton: {
        ...theme.typography.estimate,
        borderRadius: 50,
        height: 45,
        width: 245,
        fontSize: "1rem",
        backgroundColor: theme.palette.common.orkaOrange,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light
        },
        [theme.breakpoints.down("sm")]: {
            height: 40,
            width: 225
        }

    }
}))

export default function Contact(props) {
    const classes = useStyles();
    const theme = useTheme()
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

    const [name, setName] = useState("");

    const [email, setEmail] = useState("");
    const [emailHelper, setEmailHelper] = useState("");

    const [phone, setPhone] = useState("");
    const [phoneHelper, setPhoneHelper] = useState("");

    const [message, setMessage] = useState("");

    const [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const [alert, setAlert] = useState({open: false, message: "", backroundColor: ""});

    const onChange = (event) => {
        let valid;
        switch (event.target.id) {
            case 'email':
                setEmail(event.target.value)
                valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)

                if (!valid) {
                    setEmailHelper("Nesprávný formát emailu")
                } else {
                    setEmailHelper("")
                }
                break;
            case 'phone':
                setPhone(event.target.value)
                valid = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/.test(event.target.value)

                if (!valid) {
                    setPhoneHelper("Nesprávný formát telefonu")
                } else {
                    setPhoneHelper("")
                }
                break;

            default:
                break;
        }
    }

    const onConfirm = () => {
        setLoading(true);
        axios.get("https://us-central1-orka-solutions-web-d9c97.cloudfunctions.net/sendMail", {
            params: {
                name: name,
                email: email,
                phone: phone,
                message: message
            }
        })
            .then(res => {
                setLoading(false);
                setOpen(false);
                setName("");
                setEmail("");
                setPhone("");
                setMessage("");
                setAlert({open: true, message: "Zpráva úspěšně odeslána", backroundColor: "#4BB543"})
                console.log(res);
            })
            .catch(err => {
                setLoading(false);
                setAlert({open: true, message: "Něco se pokazilo, zkuste to znova", backroundColor: "#FF3232"})
            });
    }

    const buttonContent = (
        <React.Fragment>
            Odeslat zprávu
            <img src={airplane} alt={"send airplane"} style={{marginLeft: "1em"}}/>
        </React.Fragment>
    )

    return (
        <Grid container direction="row">
            <Grid
                item
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{marginBottom: matchesMD ? "5em" : 0, marginTop: matchesSM ? "1em" : matchesMD ? "5em" : 0}}
                lg={4}
                xl={3}>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography align={matchesMD ? "center" : undefined} variant="h2" style={{lineHeight: 1}}>
                                Kontaktujte nás
                            </Typography>
                            <Typography align={matchesMD ? "center" : undefined} variant="body1"
                                        style={{color: theme.palette.common.orkaBlue}}>
                                Čekáme na to...
                            </Typography>
                        </Grid>
                        <Grid item container style={{marginTop: "2em"}}>
                            <Grid item>
                                <img src={phoneIcon} alt={"phone icon"} style={{marginRight: "0.5em"}}/>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1"
                                            style={{color: theme.palette.common.orkaBlue, fontSize: "1rem"}}>
                                    <a href="tel:123456789" style={{textDecoration: "none", color: "inherit"}}>+420 123
                                        456 789</a>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container style={{marginBottom: "2em"}}>
                            <Grid item>
                                <img src={emailIcon} alt={"email icon"}
                                     style={{marginRight: "0.5em", verticalAlign: "bottom"}}/>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    style={{color: theme.palette.common.orkaBlue, fontSize: "1rem"}}>
                                    <a href="mailto:info@orkasolutions.cz"
                                       style={{textDecoration: "none", color: "inherit"}}>info@orkasolutions.cz</a>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction={"column"} style={{Width: "20em"}}>
                            <Grid item style={{marginBottom: "0.5em"}}>
                                <TextField
                                    fullWidth
                                    label={"Jméno"}
                                    id={"name"}
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}/>
                            </Grid>
                            <Grid item style={{marginBottom: "0.5em"}}>
                                <TextField
                                    fullWidth
                                    error={emailHelper.length !== 0}
                                    helperText={emailHelper}
                                    label={"Email"}
                                    id={"email"} value={email}
                                    onChange={onChange}/>
                            </Grid>
                            <Grid item style={{marginBottom: "0.5em"}}>
                                <TextField
                                    fullWidth
                                    label={"Telefon"}
                                    error={phoneHelper.length !== 0}
                                    helperText={phoneHelper}
                                    id={"phone"}
                                    value={phone}
                                    onChange={onChange}/>
                            </Grid>
                        </Grid>
                        <Grid item style={{Width: "20em"}}>
                            <TextField
                                InputProps={{disableUnderline: true}}
                                className={classes.message}
                                placeholder={"Povězte nám něco o vašem projektu"}
                                fullWidth
                                multiline
                                rows={5}
                                value={message}
                                id={"message"}
                                onChange={(event) => setMessage(event.target.value)}/>

                        </Grid>
                        <Grid item container justify="center" style={{marginTop: "2em"}}>
                            <Button
                                variant={"contained"}
                                onClick={() => setOpen(true)}
                                disabled={name.length === 0 || message.length === 0 || phoneHelper.length !== 0 || emailHelper.length !== 0}
                                className={classes.sendButton}
                            >
                                {buttonContent}
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            {/* --- Dialog odeslani */}
            <Dialog
                style={{zIndex: 1302}}
                open={open}
                fullScreen={matchesSM}
                onClose={() => setOpen(false)}
                PaperProps={{
                    style: {
                        paddingTop: matchesXS ? "1em" : "5em",
                        paddingBottom: matchesXS ? "1em" : "5em",
                        paddingLeft: matchesXS ? 0 : matchesSM ? "5em" : matchesMD ? "15em" : "25em",
                        paddingRight: matchesXS ? 0 : matchesSM ? "5em" : matchesMD ? "15em" : "25em",
                    }
                }}>
                <DialogContent>
                    <Grid container direction={"column"}>
                        <Grid item>
                            <Typography variant={"h4"} gutterBottom align={"center"}>
                                Potvrzení zprávy
                            </Typography>
                        </Grid>
                        <Grid item style={{marginBottom: "0.5em"}}>
                            <TextField
                                fullWidth
                                label={"Jméno"}
                                id={"name"}
                                value={name}
                                onChange={(event) => setName(event.target.value)}/>
                        </Grid>
                        <Grid item style={{marginBottom: "0.5em"}}>
                            <TextField
                                fullWidth
                                error={emailHelper.length !== 0}
                                helperText={emailHelper}
                                label={"Email"}
                                id={"email"} value={email}
                                onChange={onChange}/>
                        </Grid>
                        <Grid item style={{marginBottom: "0.5em"}}>
                            <TextField
                                fullWidth
                                label={"Telefon"}
                                error={phoneHelper.length !== 0}
                                helperText={phoneHelper}
                                id={"phone"}
                                value={phone}
                                onChange={onChange}/>
                        </Grid>
                    </Grid>
                    <Grid item style={{Width: matchesSM ? "100%" : "20em"}}>
                        <TextField
                            InputProps={{disableUnderline: true}}
                            className={classes.message}
                            fullWidth
                            multiline
                            rows={5}
                            value={message}
                            id={"message"}
                            onChange={(event) => setMessage(event.target.value)}/>

                    </Grid>
                    {/* Panel tlacitek */}
                    <Grid
                        item
                        container
                        direction={matchesSM ? "column" : "row"}
                        style={{marginTop: "2em"}}
                        alignItems={"center"}>
                        <Grid item>
                            <Button style={{fontWeight: 300}} color="primary"
                                    onClick={() => setOpen(false)}>Storno</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={"contained"}
                                onClick={onConfirm}
                                disabled={name.length === 0 || message.length === 0 || phoneHelper.length !== 0 || emailHelper.length !== 0}
                                className={classes.sendButton}
                            >
                                {loading ? <CircularProgress size={30}/> : buttonContent}
                            </Button>
                        </Grid>

                    </Grid>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={alert.open}
                message={alert.message}
                ContentProps={
                    {
                        style: {
                            backgroundColor: alert.backroundColor
                        }
                    }}
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                onClose={() => setAlert({...alert, open: false})}
                autoHideDuration={4000}
            />

            <Grid item container lg={8} xl={9} direction={matchesMD ? "column" : "row"}>
                <CallToAction setValue={props.setValue}/>

            </Grid>
        </Grid>
    )
}

