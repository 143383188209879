import React from 'react';
import {Link} from 'react-router-dom';
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import footerAdornment from '../../assets/images/Footer Adornment.svg';
import facebook from '../../assets/facebook.svg';
import twitter from '../../assets/twitter.svg';
import instagram from '../../assets/instagram.svg';


const useStyles = makeStyles(theme => ({
        footer: {
            backgroundColor: theme.palette.common.orkaBlue,
            width: "100%",
            zIndex: 1302,
            position: "relative"
        },
        adornment: {
            width: "25em",
            verticalAlign: "bottom",
            [theme.breakpoints.down("md")]: {
                width: "21em"
            },
            [theme.breakpoints.down("xs")]: {
                width: "15em"
            },
        },
        mainContainer: {
            position: "absolute"
        },
        link: {
            color: "white",
            fontFamily: "Arial",
            fontWeight: "bold",
            fontSize: "0.75rem",
            textDecoration: "none"

        },
        gridItem: {
            margin: "3em"
        },
        icon: {
            height: "4em",
            width: "4em",
            [theme.breakpoints.down("xs")]: {
                height: "2.5em",
                width: "2.5em"
            }
        },
        socialContainer: {
            position: "absolute",
            marginTop: "-6em",
            right: "1.5em",
            [theme.breakpoints.down("xs")]: {
                right: "0.6em"
            }

        }
    })
);


export default function Footer(props) {
    const classes = useStyles();

    return <footer className={classes.footer}>
        <Hidden mdDown>
            <Grid container className={classes.mainContainer} justify={"center"}>
                {/* ----- Home Sloupec ----- */}
                <Grid item className={classes.gridItem}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item className={classes.link} component={Link} to={"/"} onClick={() => {
                            props.setValue(0)
                        }}>
                            Domů
                        </Grid>
                    </Grid>
                </Grid>
                {/* ----- Service Sloupec ----- */}
                <Grid item className={classes.gridItem}>
                    <Grid container direction="column" spacing={2}>
                        <Grid
                            item
                            className={classes.link}
                            component={Link} to={"/services"}
                            onClick={() => {
                                props.setValue(1)
                            }}
                        >
                            Služby
                        </Grid>
                        <Grid
                            item
                            className={classes.link}
                            component={Link} to={"/customsoftware"}
                            onClick={() => {
                                props.setValue(0);
                                props.setSelectedIndex(1)
                            }}
                        >
                            Software na zakázku
                        </Grid>
                        <Grid
                            item
                            className={classes.link}
                            component={Link} to={"/mobileapps"}
                            onClick={() => {
                                props.setValue(0);
                                props.setSelectedIndex(2)
                            }}
                        >
                            Mobilní aplikace
                        </Grid>
                        <Grid
                            item
                            className={classes.link}
                            component={Link} to={"/websites"}
                            onClick={() => {
                                props.setValue(0);
                                props.setSelectedIndex(3)
                            }}
                        >
                            Web Design
                        </Grid>
                        <Grid
                            item
                            className={classes.link}
                            component={Link} to={"/firmyservis"}
                            onClick={() => {
                                props.setValue(0);
                                props.setSelectedIndex(2)
                            }}
                        >
                            Servis firemní IT
                        </Grid>
                        <Grid
                            item
                            className={classes.link}
                            component={Link} to={"/pohodaservis"}
                            onClick={() => {
                                props.setValue(0);
                                props.setSelectedIndex(4)
                            }}
                        >
                            Administrace ES Stormware POHODA
                        </Grid>
                    </Grid>
                </Grid>
                {/* ----- Revolution sloupec ----- */}
                <Grid item className={classes.gridItem}>
                    <Grid container direction="column" spacing={2}>
                        <Grid
                            item
                            className={classes.link}
                            component={Link} to={"/revolution"}
                            onClick={() => {
                                props.setValue(2)
                            }}
                        >
                            Revoluce !!!
                        </Grid>
                    </Grid>
                </Grid>

                {/* ----- A-SPED SQL Sloupec ----- */}
                {/*<Grid item className={classes.gridItem}>*/}
                {/*    <Grid container direction="column" spacing={2}>*/}
                {/*        <Grid*/}
                {/*            item*/}
                {/*            className={classes.link}*/}
                {/*            component={Link} to={"/aspedsql"}*/}
                {/*            onClick={() => {*/}
                {/*                props.setValue(3)*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            A-SPED Sql*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
                {/* ----- About Sloupec ----- */}
                <Grid item className={classes.gridItem}>
                    <Grid container direction="column" spacing={2}>
                        <Grid
                            item
                            className={classes.link}
                            component={Link} to={"/about"}
                            onClick={() => {
                                props.setValue(4)
                            }}
                        >
                            O nás
                        </Grid>
                        <Grid
                            item
                            className={classes.link}
                            component={Link} to={"/about"}
                            onClick={() => {
                                props.setValue(4)
                            }}
                        >
                            Historie
                        </Grid>
                        <Grid
                            item
                            className={classes.link}
                            component={Link} to={"/about"}
                            onClick={() => {
                                props.setValue(4)
                            }}
                        >
                            Team
                        </Grid>
                    </Grid>
                </Grid>
                {/* ----- Contact Sloupec ----- */}
                <Grid item className={classes.gridItem}>
                    <Grid container direction="column" spacing={2}>
                        <Grid
                            item
                            className={classes.link}
                            component={Link} to={"/contact"}
                            onClick={() => {
                                props.setValue(5)
                            }}
                        >
                            Kontakt
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Hidden>
        <img alt={'black decoration'} src={footerAdornment} className={classes.adornment}/>
        <Grid container className={classes.socialContainer} justify="flex-end" spacing={2}>
            <Grid
                item
                component={"a"} href="https://www.facebook.com" rel="noopener noreferrer" target="_blank"
            >
                <img alt="facebook logo" src={facebook} className={classes.icon}/>
            </Grid>
            <Grid
                item
                component={"a"} href="https://www.twitter.com" rel="noopener noreferrer" target="_blank"
            >
                <img alt="twitter logo" src={twitter} className={classes.icon}/>
            </Grid>
            <Grid
                item
                component={"a"} href="https://www.instagram.com" rel="noopener noreferrer" target="_blank"
            >
                <img alt="instagram logo" src={instagram} className={classes.icon}/>
            </Grid>
        </Grid>
    </footer>

};


