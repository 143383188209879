import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import NavArrowButton from "./UI/buttons/NavArrowButton";

const useStyles = makeStyles(theme => ({
    mainContainer: {
        paddingLeft: "5em",
        paddingRight: "5em",
        paddingTop: "2em",
        paddingBottom: "10em"
    },
    arrowContainer: {
        marginTop: "0.5em"
    },
    heading: {
        maxWidth: "40em"
    }
}))

export default function FirmyServis(props) {
    const classes = useStyles()

    return (
        <Grid container direction="column" className={classes.mainContainer}>
            <Grid item container direction="row">
                <Grid item className={classes.arrowContainer} style={{marginLeft: "-3.5em", marginRight: "1em"}}>
                    <NavArrowButton
                        link="/pohodaservis"
                        direct="back"
                        onclick={() => props.setSelectedIndex(1)}
                    />
                </Grid>
                <Grid item container direction="column" className={classes.heading}>
                    <Grid item>
                        <Typography variant="h2"> Servis firemní IT </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" paragraph>
                            Náklady na mzdy, vybavení a školení správců a administrátorů jsou značné a také neustále
                            rostou. Zbavte se této položky v rozpočtu a využijte externí správy sítě
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Používáme moderní technologie a automatizujeme Kombinujeme výhody automatizace
                            (rychlost, přesnost, nízká cena) a lidské práce (vysoká odbornost, kreativita, lidský
                            přístup). Vy získáte vysoce odborné lidi za rozumnou cenu bez kompromisů na straně
                            kvality.
                        </Typography>
                    </Grid>

                </Grid>

                <Grid item className={classes.arrowContainer}>
                    <NavArrowButton
                        link="/websites"
                        direct="fw"
                        onclick={() => props.setSelectedIndex(3)}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
