import React from 'react';
import {Link} from 'react-router-dom';
import IconButton from "@material-ui/core/IconButton";

import forwardArrow from '../../../assets/forwardArrow.svg';
import backArrow from '../../../assets/backArrow.svg';

export default function NavArrowButton(props) {
    return (
        <IconButton
            style={{backgroundColor: "transparent"}}
            component={Link} to={props.link}
            onClick={props.onclick}
        >
            <img alt="BackButton" src={props.back ? backArrow : forwardArrow}/>
        </IconButton>
    )
}