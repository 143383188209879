import React from 'react';
import {makeStyles, useTheme} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NavArrowButton from "./UI/buttons/NavArrowButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Hidden from "@material-ui/core/Hidden";

import analitics from '../assets/analytics.svg';
import seo from '../assets/seo.svg';
import outreach from '../assets/outreach.svg';
import ecomerce from '../assets/ecommerce.svg';

import CallToAction from "./UI/CallToAction";

const useStyles = makeStyles(theme => ({
    rowContainer: {
        paddingLeft: "5em",
        paddingRight: "5em",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "1.5em",
            paddingRight: "1.5em",
        }
    },
    arrowContainer: {
        marginTop: "0.5em"
    },
    heading: {
        maxWidth: "40em"
    },
    paragraphContainer: {
        maxWidth: "30em"
    }
}))

export default function Websites(props) {
    const classes = useStyles()
    const theme = useTheme()
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Grid container direction="column">
            {/* ------- Websites development ------- */}
            <Grid item container direction="row" className={classes.rowContainer}
                  justify={matchesMD ? "center" : undefined} style={{marginTop: matchesXS ? "1em" : "2em"}}>
                <Hidden mdDown>
                    <Grid item className={classes.arrowContainer} style={{marginRight: "1em", marginLeft: "-3.5em"}}>
                        <NavArrowButton link="/mobileapps" back onclick={() => props.setSelectedIndex(2)}/>
                    </Grid>
                </Hidden>
                <Grid item container direction="column" className={classes.heading}>
                    <Grid item>
                        <Typography variant="h2" align={matchesMD ? "center" : undefined}>
                            Webové stránky
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            Webové stránky jsou v dnešním obchodním světě nezbytností. Poskytují vám jedno centrální,
                            veřejné místo, aby lidem dali vědět, kdo jste, co děláte a proč jste v tom nejlepší.
                        </Typography>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            Od jednoduchého zveřejnění vašich produktů po plnohodnotný internetový obchod, aby jste se co nejvíce zpřístupnili uživatelům online a aby vám to pomohlo oslovit nové zákazníky.
                        </Typography>
                    </Grid>

                </Grid>
                <Hidden mdDown>
                    <Grid item className={classes.arrowContainer}>
                        <NavArrowButton link="/services" onclick={() => props.setSelectedIndex(0)}/>
                    </Grid>
                </Hidden>
            </Grid>
            {/* ---- Analytics ---- */}
            <Grid item container direction={matchesSM ? "column" : "row"} className={classes.rowContainer} alignItems="center" style={{marginTop: "15em"}}>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant="h4" gutterBottom align={matchesSM ? "center": undefined}>
                                Analýza
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img src={analitics} alt="analyza webu" style={{marginLeft: "-2.75em"}}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.paragraphContainer}>
                    <Typography variant="body1" align={matchesSM ? "center": undefined}>
                        Znalosti jsou síla a data jsou zlato 21. století. Analýza těchto údajů může odhalit skryté
                        vzorce a trendy ve vaší firmě, což vám umožní činit chytřejší rozhodnutí s měřitelnými účinky.
                    </Typography>
                </Grid>
            </Grid>
            {/* ---- e-Comercs ---- */}
            <Grid item container direction={matchesSM ? "column" : "row"}  className={classes.rowContainer} alignItems="center" justify="flex-end" style={{marginBottom: "15em", marginTop: "15em"}}>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant="h4" gutterBottom align="center">
                                E-Comerce
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img src={ecomerce} alt="world outline of dollar" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{marginLeft: matchesSM ? 0 : "1em"}} className={classes.paragraphContainer}>
                    <Typography variant="body1" paragraph  align={matchesSM ? "center": undefined}>
                        Není tajemstvím, že lidé rádi nakupují online.
                    </Typography>
                    <Typography variant="body1" paragraph  align={matchesSM ? "center": undefined}>
                        V roce 2017 bylo v elektronickém obchodě vynaloženo přes 2,3 bilionu dolarů a je čas na kousek tohoto koláčku.
                    </Typography>

                </Grid>
            </Grid>
            {/* ---- Outreach ---- */}
            <Grid item container direction={matchesSM ? "column" : "row"} className={classes.rowContainer} alignItems="center">
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant="h4" gutterBottom align={matchesSM ? "center": undefined}>
                                Dosah
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img src={outreach} alt="megafon"/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{marginLeft: matchesSM ? 0 : "1em"}} className={classes.paragraphContainer}>
                    <Typography variant="body1"  align={matchesSM ? "center": undefined}>
                        Přilákejte lidi oslnivou webovou stránkou. Předvádění vašich produktů online je skvělý způsob, jak zákazníkům pomoci rozhodnout se, co je pro ně správné, než navštíví osobně.
                    </Typography>
                </Grid>
            </Grid>
            {/* ---- SEO ---- */}
            <Grid item container direction={matchesSM ? "column" : "row"} className={classes.rowContainer} alignItems="center" justify="flex-end" style={{marginBottom: "15em", marginTop: "15em"}}>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant="h4" gutterBottom align="center">
                                Optimalizace <br/> pro vyhledávače
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img src={seo} alt="seo ikona" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{marginLeft: matchesSM ? 0 : "1em"}} className={classes.paragraphContainer}>
                    <Typography variant="body1" paragraph  align={matchesSM ? "center": undefined}>
                        Jak často jste někdy byli na druhé stránce s výsledky Google?
                    </Typography>
                    <Typography variant="body1" paragraph  align={matchesSM ? "center": undefined}>
                        Pokud jste jako my, pravděpodobně nikdy.
                    </Typography>
                    <Typography variant="body1" paragraph  align={matchesSM ? "center": undefined}>
                        Zákazníci tam také nepřijdou, takže se ujistíme, že váš web je navržen tak, aby skončil nahoře.
                    </Typography>

                </Grid>
            </Grid>
            <Grid item>
                <CallToAction setValue={props.setValue} />
            </Grid>

        </Grid>
    )
}
