import React from 'react';
import {makeStyles, useTheme} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import vision from "../assets/vision.svg";
import Lottie from "react-lottie";
import technologyAnimation from "../animations/technologyAnimation/data.json";
import consultation from "../assets/consultationIcon.svg";
import mockup from "../assets/mockupIcon.svg";
import review from "../assets/reviewIcon.svg";
import build from "../assets/buildIcon.svg";
import design from "../assets/designIcon.svg";
import launch from "../assets/launchIcon.svg";
import maintain from "../assets/maintainIcon.svg";
import iterate from "../assets/iterateIcon.svg";

const useStyles = makeStyles(theme => ({
    rowContainer: {
        paddingLeft: "5em",
        paddingRight: "5em",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "1.5em",
            paddingRight: "1.5em",
        }
    },
}))

export default function Revolution(props) {
    const classes = useStyles()
    const theme = useTheme()
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: technologyAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Grid
            container
            direction="column">
            {/* Revolution */}
            <Grid
                item
                className={classes.rowContainer}
                style={{marginTop: "2em"}}>
                <Typography
                    variant="h2"
                    align={matchesMD ? "center" : undefined}
                    style={{fontFamily: "Pacifico"}}>
                    Revoluce !!!
                </Typography>
            </Grid>
            {/* --- Vision --- */}
            <Grid
                container
                direction={matchesMD ? "column" : "row"}
                alignItems="center"
                style={{marginTop: matchesMD ? 0 : "5em"}}
                className={classes.rowContainer}>
                <Grid item lg>
                    <img
                        src={vision}
                        alt="vision images"
                        style={{
                            maxWidth: matchesSM ? 300 : "40em",
                            marginRight: matchesMD ? 0 : "5em",
                            marginBottom: matchesMD ? "5em" : 0
                        }}
                    />
                </Grid>
                <Grid item container direction="column" lg style={{maxWidth: "40em"}}>
                    <Grid item>
                        <Typography variant="h4" gutterBottom align={matchesMD ? "center" : "right"}>
                            Vize
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : "right"} variant="body1" paragraph>
                            Vzestup počítačů a následně internetu zcela změnil všechny aspekty lidského života. To
                            zvýšilo naše pohodlí, rozšířilo naše spojení a změnilo to, jak vidíme svět.
                        </Typography>
                        <Typography align={matchesMD ? "center" : "right"} variant="body1" paragraph>
                            To, co bylo kdysi omezeno na obrovské místnosti a týmy techniků, nyní sídlí v každé z našich
                            rukou. Využití tohoto neomezeného potenciálu jeho řešením k řešení problémů a lepších životů
                            je jádrem všeho, co děláme.
                        </Typography>
                        <Typography align={matchesMD ? "center" : "right"} variant="body1" paragraph>
                            Chceme pomáhat podnikům vydělávat na nejnovějších a nejlepších technologiích. Nejlepší
                            způsob, jak předpovědět budoucnost, je ten, který jej buduje, a my chceme pomoci světu
                            přivést k další kapitole technologické expanze, zkoumání a inovací.
                        </Typography>
                        <Typography align={matchesMD ? "center" : "right"} variant="body1" paragraph>
                            Tím, že se držíme přísných standardů a nedotčené kvality, můžeme zajistit, že budete mít
                            absolutně nejlepší nástroje potřebné k prosperitě v této nové hranici.
                        </Typography>
                        <Typography align={matchesMD ? "center" : "right"} variant="body1" paragraph>
                            Vidíme budoucnost, kdy každý jednotlivec má přizpůsobený software přizpůsobený jejich
                            životnímu stylu, kultuře a zájmům, který jim pomáhá překonávat životní překážky. Každý
                            projekt je krokem k tomuto cíli.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* ------------- End Vision*/}

            {/* --- Technologie --- */}
            <Grid
                container
                direction={matchesMD ? "column" : "row"}
                alignItems="center"
                style={{marginTop: "10em", marginBottom: "10em"}}
                className={classes.rowContainer}>
                <Grid item container direction="column" lg style={{maxWidth: "40em"}}>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="h4" gutterBottom>
                            Technologie
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            V roce 2013 Facebook vymyslel nový způsob vytváření webových stránek. Tento nový systém,
                            React.js, zcela revolucionizuje proces a praxi vývoje webových stránek.
                        </Typography>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            Namísto řetězení dlouhých jednotlivých stránek, jako jsou tradiční weby, jsou webové stránky
                            React vytvořeny s malými kousky kódu nazývaného komponenty. Tyto komponenty jsou rychlejší,
                            snadnější na údržbu a snadno se znovu používají a přizpůsobují, přičemž každá z nich slouží
                            jedinečnému účelu.
                        </Typography>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            O dva roky později šokovali svět tím, že vydali podobný systém React Native pro výrobu
                            aplikací pro iOS a Android. Namísto toho, abyste museli ovládat dvě zcela oddělené vývojové
                            platformy, můžete využít znalosti, které již vlastníte, z vytváření webových stránek a znovu
                            je přímo použít! Byl to obrovský skok vpřed.
                        </Typography>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            Tuto technologii nyní používají společnosti jako AirBnB, Microsoft, Netflix, Pinterest,
                            Skype, Tesla, UberEats, a když si Facebook zakoupil Instagram, velké části se dokonce pomocí
                            Reactu přestavěly.
                        </Typography>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            Vývojáři se od té doby stavěli na těchto systémech automatizací nastavení a nasazení
                            projektu, což tvůrcům umožnilo soustředit se co nejvíce na vlastní práci.
                        </Typography>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            Tato technická vylepšení se promítají do úspor tím, že významně sníží pracovní vytížení a
                            zefektivňují pracovní postup při vývoji nových kusů softwaru a zároveň snižují překážku
                            vstupu na trh pro vývoj mobilních aplikací.
                        </Typography>
                        <Typography variant="body1" paragraph align={matchesMD ? "center" : undefined}>
                            Díky tomu bude personalizace v kapse rychlejší, lepší a cenově dostupnější než kdykoli
                            předtím.
                        </Typography>
                    </Grid>


                </Grid>
                <Grid item container lg justify={matchesMD ? "center" : "flex-end"}>
                    <Lottie options={defaultOptions} isStopped={true} style={{maxWidth: "40em", margin: 0}}/>
                </Grid>

            </Grid>
            )
            {/* ------------- End Technologie*/}

            {/* --- Procesy --- */}
            <Grid
                item
                container
                direction={matchesMD ? "column" : "row"}
                justify="center"
                className={classes.rowContainer}>
                <Grid item>
                    <Typography variant="h4" gutterBottom>
                        Procesy
                    </Typography>
                </Grid>
            </Grid>
            {/* --- Konzultace --- */}
            <Grid
                item
                container
                direction={matchesMD ? "column" : "row"}
                className={classes.rowContainer}
                style={{backgroundColor: "#b3b3b3", height: "90em"}}>
                <Grid
                    item
                    container
                    direction="column"
                    alignItems={matchesMD ? "center" : undefined}
                    lg>
                    <Grid item>
                        <Typography
                            variant="h4"
                            align={matchesMD ? "center" : undefined}
                            gutterBottom
                            style={{color: "#000", marginTop: "5em"}}>
                            Konzultace
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body1"
                            align={matchesMD ? "center" : undefined}
                            paragraph
                            style={{color: "#fff", maxWidth: "20em"}}>
                            Náš proces začíná okamžikem, kdy si uvědomíte, že pro své podnikání potřebujete kus
                            technologie. Ať už již máte představu o tom, kde začít a co dělat, nebo pokud prostě víte,
                            že chcete věci vylepšit, naše počáteční konzultace vám pomůže holisticky prozkoumat vaše
                            podnikání a najít nejlepší řešení.
                        </Typography>
                        <Typography variant="body1" paragraph style={{color: "#fff", maxWidth: "20em"}}>
                            Budou zaznamenány podrobné poznámky k vašim požadavkům a omezením, přičemž je třeba věnovat
                            pozornost identifikaci dalších potenciálních oblastí k posouzení.
                        </Typography>
                        <Typography variant="body1" paragraph style={{color: "#fff", maxWidth: "20em"}}>
                            Špičkový pokrok ve strojovém učení, jako je detekce objektů a zpracování přirozeného jazyka,
                            umožňuje počítačům dělat věci, které byly dříve nepředstavitelné, a naše odborné znalosti a
                            intuice vám pomohou uvést vás do této nové budoucnosti možností.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg style={{alignSelf: "center"}}>
                    <img
                        src={consultation}
                        alt="konzultace ikona"
                        width="100%"
                        style={{maxWidth: 700}}/>
                </Grid>

            </Grid>
            )
            {/* --- Mockup --- */}
            <Grid
                item
                container
                direction={matchesMD ? "column" : "row"}
                className={classes.rowContainer}
                style={{backgroundColor: "#FF7373", height: "90em"}}>
                <Grid
                    item
                    container
                    direction="column"
                    alignItems={matchesMD ? "center" : undefined}
                    lg>
                    <Grid item>
                        <Typography variant="h4" gutterBottom style={{color: "#000", marginTop: "5em"}}
                                    align={matchesMD ? "center" : undefined}>
                            Návrh (maketa)
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" paragraph style={{color: "#fff", maxWidth: "20em"}}
                                    align={matchesMD ? "center" : undefined}>
                            Poté, co se usadíme na nejlepší cestě vpřed a rozhodneme se o řešení, které bude pokračovat,
                            budou dokončeny podrobnosti, jako je cena a časová osa.
                        </Typography>
                        <Typography variant="body1" paragraph style={{color: "#fff", maxWidth: "20em"}}
                                    align={matchesMD ? "center" : undefined}>
                            Pak je čas začít s minimálním životaschopným produktem. Je to jen fantastický výraz pro
                            maketu, která nezahrnuje barvy, obrázky ani jiné leštěné designové prvky, ale zachycuje
                            základní strukturu a funkčnost rozvržení.
                        </Typography>
                        <Typography variant="body1" paragraph style={{color: "#fff", maxWidth: "20em"}}
                                    align={matchesMD ? "center" : undefined}>
                            To nám pomáhá porozumět a zdokonalit samotné řešení, než se rozptýlí podle specifik a
                            vzhledů.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg style={{alignSelf: "center"}}>
                    <img
                        src={mockup}
                        width="100%"

                        alt="maketa ikona" style={{maxWidth: 1000}}/>
                </Grid>

            </Grid>
            )
            {/* --- Review --- */}
            <Grid
                item
                container
                direction={matchesMD ? "column" : "row"}
                className={classes.rowContainer}
                style={{backgroundColor: "#39B54A", height: "90em"}}>
                <Grid
                    item
                    container
                    direction="column"
                    alignItems={matchesMD ? "center" : undefined}
                    lg>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="h4" gutterBottom
                                    style={{color: "#000", marginTop: "5em"}}>
                            Posouzení
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Než se přesuneme dále, vrátíme se k vám s naším pokrokem. To vám dává svobodu diskutovat o
                            jakýchkoli změnách nebo o jakýchkoli nápadech, s nimiž jste přišli, než dojde k jakémukoli
                            těžkému zvedání.
                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Než se přesuneme dále, vrátíme se k vám s naším pokrokem. To vám dává svobodu diskutovat o
                            jakýchkoli změnách nebo o jakýchkoli nápadech, s nimiž jste přišli, než dojde k jakémukoli
                            těžkému zvedání.
                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Než se přesuneme dále, vrátíme se k vám s naším pokrokem. To vám dává svobodu diskutovat o
                            jakýchkoli změnách nebo o jakýchkoli nápadech, s nimiž jste přišli, než dojde k jakémukoli
                            těžkému zvedání. </Typography>
                    </Grid>
                </Grid>
                <Grid item lg style={{alignSelf: "center"}}>
                    <img src={review} alt="lupa ikona" width="100%"/>
                </Grid>

            </Grid>
            )
            {/* --- Design --- */}
            <Grid
                item
                container
                direction={matchesMD ? "column" : "row"}
                className={classes.rowContainer}
                style={{backgroundColor: "#a67c52", height: "90em"}}>
                <Grid
                    item
                    container
                    direction="column"
                    alignItems={matchesMD ? "center" : undefined}
                    lg>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="h4" gutterBottom
                                    style={{color: "#000", marginTop: "5em"}}>
                            Design
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>

                            Pomocí maket a poznámek pořízených během konzultace jako průvodce začneme vyžehlit, jak bude
                            vypadat konečný produkt. To také zahrnuje použití jakýchkoli značkových materiálů, jako jsou
                            písma, barvy a loga, k rozšíření zážitku, který již znáte.
                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Žádný aspekt není nadbytečný a při každém rozhodnutí bude věnována pozornost.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg style={{alignSelf: "center"}}>
                    <img src={design} alt="lupa ikona" style={{maxWidth: 1000}} width="100%"/>
                </Grid>

            </Grid>
            )
            {/* --- Second Review --- */}
            <Grid
                item
                container
                direction={matchesMD ? "column" : "row"}
                className={classes.rowContainer}
                style={{backgroundColor: "#39b54a", height: "90em"}}>
                <Grid
                    item
                    container
                    direction="column"
                    alignItems={matchesMD ? "center" : undefined}
                    lg>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="h4" gutterBottom
                                    style={{color: "#000", marginTop: "5em"}}>
                            Posouzení (opět)
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Druhé kolo recenze je zásadní pro náš cíl vytvořit přesně to, co chcete, přesně jak to
                            chcete.
                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Tentokrát projdeme dokončené návrhy v další plně interaktivní demonstraci. Opět vám to dává
                            příležitost vyladit věci a ujistit se, že dostaneme všechno správně poprvé.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg style={{alignSelf: "center"}}>
                    <img src={review} alt="lupa ikona dalsi" width="100%"/>
                </Grid>

            </Grid>
            )
            {/* --- Build --- */}
            <Grid
                item
                container
                direction={matchesMD ? "column" : "row"}
                className={classes.rowContainer}
                style={{backgroundColor: "#fbb03b", height: "90em"}}>
                <Grid
                    item
                    container
                    direction="column"
                    alignItems={matchesMD ? "center" : undefined}
                    lg>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="h4" gutterBottom
                                    style={{color: "#000", marginTop: "5em"}}>
                            Generování projektu
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Zde se dostáváme k podnikání.
                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Inženýrství začíná po schválení konečných návrhů. Začínáme lešením projektu na vysoké
                            úrovni, upřednostňováním některých oblastí před ostatními.
                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Každá oblast je pak vyvíjena v pořadí podle důležitosti, dokud není připravena k připojení k
                            dalšímu kusu.

                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Typicky je backend, za scénami, operace dokončeny první. Jakmile jsou všechny služby na
                            místě, můžeme vytvořit frontend, uživatelskou stránku věcí.
                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Dokončení aplikace však neznamená, že jsme hotovi, protože k zajištění kompatibility se
                            všemi zamýšlenými zařízeními používáme rozsáhlé testování.
                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Teprve po důkladných kontrolách přijmeme produkt jako hotový a poté jej protlačíme výrobním
                            potrubím. Výsledkem je optimalizovaná, komprimovaná spotřebitelská verze kódu a prostředků
                            připravených k nasazení.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg style={{alignSelf: "center"}}>
                    <img src={build} alt="construction site" style={{maxWidth: matchesMD ? 700 : 1000}} width="100%"/>
                </Grid>
            </Grid>
            )
            {/* --- Launch --- */}
            <Grid
                item
                container
                direction={matchesMD ? "column" : "row"}
                className={classes.rowContainer}
                style={{backgroundColor: "#c1272d", height: "90em"}}>
                <Grid
                    item
                    container
                    direction="column"
                    alignItems={matchesMD ? "center" : undefined}
                    lg>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="h4" gutterBottom
                                    style={{color: "#000", marginTop: "5em"}}>
                            Spuštění aplikace
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Chvíle, na kterou jsme všichni čekali.
                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Když se stavba blíží ke konci, jste první, kdo to věděl. Dáme naši poslední demonstraci,
                            abychom předvedli váš nový lesklý software v přírodě, takže přesně víte, jak to bude vypadat
                            vašim uživatelům.
                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Když vyslovíte slovo, stiskneme tlačítko a spustíme váš projekt pro veřejnost. Jsme tu
                            proto, abychom zajistili, že vše půjde do plánu, abyste mohli okamžitě začít využívat výhody
                            vašich technologických investic.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg style={{alignSelf: "center"}}>
                    <img src={launch} alt="rocket" style={{maxWidth: matchesSM ? 100 : 200}} width="100%"/>
                </Grid>

            </Grid>
            )
            {/* --- Maintain --- */}
            <Grid
                item
                container
                direction={matchesMD ? "column" : "row"}
                className={classes.rowContainer}
                style={{backgroundColor: "#8e45ce", height: "90em"}}>
                <Grid
                    item
                    container
                    direction="column"
                    alignItems={matchesMD ? "center" : undefined}
                    lg>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="h4" gutterBottom
                                    style={{color: "#000", marginTop: "5em"}}>
                            Následná podpora
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Naše práce tím nekončí.
                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Po úspěšném spuštění jsme v úzkém kontaktu, abychom poslouchali zpětnou vazbu a slyšeli, jak
                            je projekt přijímán.
                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Od té doby se ujišťujeme, že vaše aplikace je neustále aktuální a že využíváme nejlepších
                            dostupných funkcí a postupů. Jakmile dojde k novému vývoji nebo budou-li objeveny nové
                            techniky v budoucích projektech, provedeme tyto pokroky ve vašem projektu jako součást naší
                            běžné údržby.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg style={{alignSelf: "center"}}>
                    <img src={maintain} alt="maintain logo" style={{maxWidth: 500}} width="100%"/>
                </Grid>

            </Grid>

            )
            {/* --- Opakování --- */}
            <Grid
                item
                container
                direction={matchesMD ? "column" : "row"}
                className={classes.rowContainer}
                style={{backgroundColor: "#29abe2", height: "90em"}}>
                <Grid
                    item
                    container
                    direction="column"
                    alignItems={matchesMD ? "center" : undefined}
                    lg>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="h4" gutterBottom
                                    style={{color: "#000", marginTop: "5em"}}>
                            Vše se může opakovat
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Cyklus se opakuje, kdykoli přijdete s novým nápadem pro rozšíření vašeho aktuálního
                            projektu, nebo přijdete s úplně novým systémem.
                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Plánováním budoucích funkcí a změn můžeme vaši aplikaci v průběhu času vytvářet a vyvíjet. S
                            vývojem nových případů použití a potřeb zákazníků můžeme reagovat kontinuální integrací
                            nového obsahu.
                        </Typography>
                        <Typography align={matchesMD ? "center" : undefined} variant="body1" paragraph
                                    style={{color: "#fff", maxWidth: "20em"}}>
                            Náš iterační proces vás udrží aktuální a konkurenceschopný a umožní vám rychle implementovat
                            změny místo čekání měsíců na jednu aktualizaci.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg style={{alignSelf: "center"}}>
                    <img src={iterate} alt="falling domino" width="100%"/>
                </Grid>

            </Grid>
            )
        </Grid>
    )
}
